import { Routes } from '@angular/router';
import { AuthGuardService } from '../shared/apiProviders/auth-guard.service';
import { MainPageComponent } from './main-page/main-page.component';
import { FieldDataUploadComponent } from './field-data-upload/field-data-upload.component';
import { ParameterComponent } from './parameter/parameter.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { DataCompareComponent } from './data-compare/data-compare.component';
import { ApproveFileComponent } from './field-data-upload/approve-file/approve-file.component';
import { PageUserComponent } from './page-user/page-user.component';
import { UserProfileInfoComponent } from './user-profile-info/user-profile-info.component';
import { CheckInputComponent } from './field-data-upload/check-input/check-input.component';

export const LAYOUT_ROUTES: Routes = [
  {
    path: 'procedure-d-enregistrement-collectif',
    component: MainHeaderComponent,
    children: [
      { path: '', redirectTo: 'enregistrement', pathMatch: 'full' },
      { path: 'statistique', component:  MainPageComponent, canActivate: [AuthGuardService] },
      // { path: 'dash', component:  DashboardComponent },
      { path: 'enregistrement', component:  FieldDataUploadComponent,canActivate: [AuthGuardService]},
      { path: 'parameter', component:  ParameterComponent, canActivate: [AuthGuardService] },
      { path: 'visual-data', component:  DataCompareComponent, canActivate: [AuthGuardService]},
      { path: 'approve/:code', component:  ApproveFileComponent, canActivate: [AuthGuardService]},
      { path: 'check-file/:filename', component:  CheckInputComponent, canActivate: [AuthGuardService]},
      { path: 'user-system', component:  PageUserComponent, canActivate: [AuthGuardService]},
      { path: 'profile-user', component:  UserProfileInfoComponent, canActivate: [AuthGuardService]}
    ]
  }
];
