
<form #observationForm="ngForm" name="observationForm" (ngSubmit)="save()" >
    <h4 style="text-align: center;">
        VOULEZ-VOUS PARTAGER LES DONNEES DU FICHIER ?
    </h4>


    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
      Annuler
    </button> &nbsp;
    <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="submit">
      <span>
        <i class="mdi mdi-database-edit"></i>
        <span *ngIf="lineBar" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
        {{validate}}
      </span>
    </button>
    </div>

</form>