<style>

    .bgReceive {
        color: #2e2e2e;
    }

    .bgInprogress {
        color: #2400ee;
    }

    .bgWrong {
        color: #c92c05
    }

    .bgAccepted {
        color: #02aca3
    }

    .bgCompleted {
        color: #379737;
    }

    .bgTransInProg {
        color: #f39d72;
    }


    .bgTransfered {
        color: #0a5e96;
    }
</style>

<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/procedure-d-enregistrement-collectif/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/procedure-d-enregistrement-collectif/upload-data']">Données</a></li>
                                <li class="breadcrumb-item active">Listes</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">

                <div class="col-lg-12">
                    <div class="card-box">
                        <div class="dropdown float-right">
                            <div class="row">
                                <div class="col-md-3" >
                                    <div class="form-group">
                                        <label for="example-select">Code projet</label>
                                        <input type="text" class="form-control" id="field-1" placeholder="code du projet" name="code" [(ngModel)]="code" required (keyup)="retrieveAlldatas()" >
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="example-select">Type</label>
                                        <select class="form-control" id="example-select" [(ngModel)]="action" name="action" id="action" (change)="retrieveAlldatas()" style="font-size: 15px;">
                                      <option value="TOUT">Tout</option>
                                      <option  *ngFor="let action of ACTION" value="{{ action.key }}">{{ action.value }}</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="example-select">Statut</label>
                                        <select class="form-control" id="example-select" [(ngModel)]="statut" name="statut" id="statut" (change)="retrieveAlldatas()" style="font-size: 15px;">
                                        <option value="TOUT">Tout</option>
                                        <option *ngFor="let statut of STATUT" value="{{ statut.key }}">{{ statut.value }}</option>
                                      </select>
                                    </div>
                                </div>
                                <div class="col-md-3" style="margin-top: 25px;">
                                    <!-- <button *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD']" type="button" class="btn btn-outline-primary btn-rounded waves-effect waves-light" data-toggle="modal" data-target="#uploadFieldData" data-backdrop="static" data-keyboard="false">
                                        <i class="mdi mdi-upload-outline"></i>
                                        Soumettre le fichier
                                    </button> -->
                                    <button *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE','EFBP_PRD']" type="button" class="btn btn-outline-success btn-rounded waves-effect waves-light" (click)="uploaded({option: 'UPLOAD'})">
                                        <i class="mdi mdi-upload-outline"></i>
                                        Soumettre le fichier
                                    </button>
                                </div>
                            </div>
                        </div>


                        <h4 class="header-title">DONNEES</h4>
                        <p class="sub-header">
                            Liste des données collectées sur le terrain
                             &nbsp;
                        <!-- </p><span appCustom>Test directive</span> -->

                        <div class="table-responsive">
                            <mat-progress-bar mode="indeterminate" *ngIf="_progress"></mat-progress-bar>
                            <table class="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th>Code Projet</th>
                                        <th>Fichier</th>
                                        <th>Type Validation</th>
                                        <th>Lieu Publication</th>
                                        <th>Statut</th>
                                        <th>Date Soumission</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="showAlert">
                                    <tr>
                                        <td colspan="7">
                                            <div style="text-align: center;" class="alert alert-danger" role="alert">
                                                Pas de fichiers uploadés
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!_progress">
                                    <tr style="font-weight: bold;" *ngFor="let data of datas | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRec }"
                                       [ngClass]="{ bgReceive: data.status ==='RECEIVED', bgInprogress: data.status ==='INPROGRESS',  bgWrong: data.status ==='REJETTED', bgAccepted: data.status ==='ACCEPTED',  bgCompleted: data.status ==='COMPLETED', bgTransInProg: data.status ==='TRANSFERT_IN_PROGRESS', bgTransfered: data.status === 'TRANSFERED'}">

                                       <td style="padding-left: 15px;">{{data.projectCode}}</td>
                                        <td style="font-size: 12px;">{{data.filename}}</td>
                                        <!-- <td *ngIf="data.actionUpload === 'ONLY_VALIDATION'; then data.actionUpload === 'VALIDATION_AND_PUBLICATION'; else data.actionUpload === 'APPROVAL_AFTER_INSPECTION'"> {{data.actionUpload}}</td> -->
                                        <td style="text-align: left;" *ngIf="data.actionUpload === 'ONLY_VALIDATION'">Validation seule</td>
                                        <td style="text-align: left;" *ngIf="data.actionUpload === 'VALIDATION_AND_PUBLICATION'"> Avec publicité</td>
                                        <td style="text-align: left;" *ngIf="data.actionUpload === 'APPROVAL_AFTER_INSPECTION'"> Après publicité </td>
                                        <td style="text-align: left;" *ngIf="data.actionUpload === 'ONLY_VALIDATION_AFTER_INSPECTION'"> Pré-Comparaison </td>
                                        <td>{{(data.placeOfPublication === 'undefined') || (data.placeOfPublication === "") ? 'Néant' : data.placeOfPublication }}</td>
                                        <td style="text-align: left;" *ngIf="data.status === 'RECEIVED'"> Reçu </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'INPROGRESS'"> En attente </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'REJETTED'"> Rejeté </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'ACCEPTED'"> Validé </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'COMPLETED'"> Autorisé </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'TRANSFERT_IN_PROGRESS'"> TRANSFERT EN COURS </td>
                                        <td style="text-align: left;" *ngIf="data.status === 'TRANSFERED'"> Transféré </td>
                                        <td>{{data.submission | date: 'short' }}</td>
                                        <td class="row">
                                            <div class="col-sm-6">

                                                <div class="dropdown" style="margin-top: 10px;" >
                                                    <a *ngIf=" data.status !== 'RECEIVED' || data.status !== 'REJETTED' || data.status !==  'TRANSFERT_IN_PROGRESS' || data.status !==  'TRANSFERED' "  href="#" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i class="mdi mdi-dots-vertical"></i>
                                                        <span class="small text-dark">Documents</span>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <!-- item-->
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE',  'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL', 'EFBP_PRD_ASS_PROJ']">
                                                          <a  *ngIf="(data.status === 'ACCEPTED' || data.status === 'REJETTED' || data.status === 'INPROGRESS' || data.status === 'COMPLETED'  || data.status ===  'TRANSFERED' || data.status === 'REJETTED') && (data.actionUpload === 'ONLY_VALIDATION' || data.actionUpload === 'VALIDATION_AND_PUBLICATION' || data.actionUpload === 'APPROVAL_AFTER_INSPECTION'|| data.actionUpload === 'ONLY_VALIDATION_AFTER_INSPECTION' )" (click)="rapport(data.filename)" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf"></i> &nbsp; Rapport de Validation
                                                          </a>
                                                        </span>
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE',  'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL', 'EFBP_PRD_ASS_PROJ']">
                                                          <a  *ngIf=" data.status === 'ACCEPTED' &&  data.actionUpload === 'ONLY_VALIDATION_AFTER_INSPECTION' " (click)="rapport_comparaison(data.filename)" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf"></i> &nbsp; Rapport de comparaison
                                                          </a>
                                                        </span>
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD_ASS_PROJ', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']">
                                                            <a *ngIf="(data.status === 'ACCEPTED' || data.status ===  'TRANSFERED' || data.status === 'COMPLETED') && (data.actionUpload === 'VALIDATION_AND_PUBLICATION' || data.actionUpload === 'APPROVAL_AFTER_INSPECTION' )" (click)="cadastralMap(data.filename)" class="dropdown-item">
                                                                <i class="mdi mdi-file-pdf-box"></i>&nbsp; Plan Cadastral
                                                            </a>
                                                          </span>
                                                        <span style="color: black;" style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE',  'EFBP_PRD_ASS_PROJ', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']">
                                                            <a *ngIf="(data.status === 'ACCEPTED' || data.status === 'COMPLETED'  || data.status ===  'TRANSFERED') && (data.actionUpload === 'VALIDATION_AND_PUBLICATION' || data.actionUpload === 'APPROVAL_AFTER_INSPECTION' )" (click)="repertoire({data: data, OPTION: 'SORTED'})" class="dropdown-item">
                                                                <i class="mdi mdi-file-pdf-box"></i>&nbsp; Repertoire(PDF)
                                                            </a>
                                                        </span>
                                                          <!-- item-->
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD_ASS_PROJ', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']">
                                                          <a *ngIf=" (data.status === 'ACCEPTED' || data.status === 'COMPLETED' || data.status ===  'TRANSFERED' ) && (data.actionUpload === 'VALIDATION_AND_PUBLICATION' || data.actionUpload ===  'APPROVAL_AFTER_INSPECTION' ) " target="_blank"  href="{{url2 + data.filename}}" class="dropdown-item">
                                                              <i class="mdi mdi-microsoft-excel"></i>&nbsp; Repertoire(Excel)
                                                          </a>
                                                        </span>
                                                        <!-- item-->
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE']">
                                                          <a *ngIf="data.status === 'ACCEPTED' && data.actionUpload === 'VALIDATION_AND_PUBLICATION' " (click)="updated({data: data, option: 'UPDATE'})" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf-box"></i>&nbsp; Modification
                                                          </a>
                                                        </span>
                                                        <!-- item-->
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_ADM_FUNCTIONAL' ]">
                                                          <a *ngIf="data.status === 'ACCEPTED' && (data.actionUpload === 'APPROVAL_AFTER_INSPECTION' || data.actionUpload === 'VALIDATION_AND_PUBLICATION'  )"  (click)="AnnulerProject(data.id, data.projectCode)" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf-box"></i>&nbsp; Annuler ce projet
                                                          </a>
                                                        </span>
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO']">
                                                          <a *ngIf="data.status === 'ACCEPTED' && data.actionUpload ===( 'VALIDATION_AND_PUBLICATION' || 'APPROVAL_AFTER_INSPECTION' ) " [routerLink]="['/procedure-d-enregistrement-collectif/check-file', data.filename]" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf-box"></i>&nbsp; Vérification Fichier GeoJSON
                                                          </a>
                                                        </span>
                                                        <span style="color: black;" *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO']">
                                                          <a *ngIf="data.status === 'COMPLETED' && data.actionUpload ===( 'VALIDATION_AND_PUBLICATION' || 'APPROVAL_AFTER_INSPECTION' ) " [routerLink]="['/procedure-d-enregistrement-collectif/check-file', data.filename]" class="dropdown-item">
                                                              <i class="mdi mdi-file-pdf-box"></i>&nbsp; Vérification Fichier GeoJSON
                                                          </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" style="margin-left: -5px;">

                                                <div class="dropdown" style="margin-top: 10px; margin-left: -15px;" *ngIf="(data.status === 'ACCEPTED' || data.status === 'INPROGRESS' || data.status === 'COMPLETED') && (data.actionUpload === 'VALIDATION_AND_PUBLICATION' || data.actionUpload === 'APPROVAL_AFTER_INSPECTION')">

                                                    <a href="#" *ngxPermissionsOnly="['EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">

                                                        <span  *ngIf="data.status === 'COMPLETED' && data.actionUpload === 'APPROVAL_AFTER_INSPECTION'" >
                                                            <span class="small text-dark" *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']"  (click)="shared({data: data, OPTION: 'SHARED'})" >
                                                                <i class="mdi mdi-file-send"></i>
                                                                Transférer fichier
                                                            </span>
                                                        </span>
                                                        <span *ngxPermissionsOnly="['EFBP_PRD_ASS_PROJ', 'EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_ADM_FUNCTIONAL']" >
                                                            <span  class="small text-dark" *ngIf="data.status === 'ACCEPTED' &&  data.actionUpload === 'APPROVAL_AFTER_INSPECTION'" (click)="approve(data.projectCode)" >
                                                               <i class="mdi mdi-check-bold"></i>
                                                                Approbation
                                                            </span>
                                                        </span>
                                                        <span  class="small text-dark" *ngIf="data.status === 'INPROGRESS' " >
                                                            <i class="mdi mdi-gesture-tap-box"></i>Validation visuelle
                                                        </span>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right" >
                                                        <!-- item-->
                                                        <button *ngIf="data.status === 'INPROGRESS' "   class="dropdown-item" >
                                                            <a *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']"  target="_blank" href="{{url + data.filename}}">
                                                                <i class="mdi mdi-download"></i>&nbsp; Fichier GeoJSON
                                                            </a>
                                                        </button>
                                                        <!-- item-->
                                                        <button *ngIf="data.status === 'INPROGRESS' "   class="dropdown-item" >
                                                            <a *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']"  target="_blank" href="{{urlJsonsource + data.filename}}">
                                                                <i class="mdi mdi-download"></i>&nbsp; Fichier GeoJSON Source
                                                            </a>
                                                        </button>
                                                        <!-- item-->
                                                        <button *ngIf="data.status === 'INPROGRESS' "   class="dropdown-item" >
                                                            <a *ngxPermissionsOnly="['EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']"  target="_blank" href="{{urlExcSource + data.filename}}">
                                                                <i class="mdi mdi-download"></i>&nbsp; Fichier Excel Source
                                                            </a>
                                                        </button>

                                                        <!-- item-->
                                                        <button  *ngIf="data.status === 'INPROGRESS' " class="dropdown-item"  [disabled]="data.status === 'ACCEPTED'">
                                                            <a *ngxPermissionsOnly="[ 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_ADM_FUNCTIONAL']"  (click)="open({option: 'ADD', data: data})">
                                                                <i class="mdi mdi-alert-circle-check"></i> &nbsp; Validation
                                                            </a>
                                                        </button>


                                                    </div>
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="text-align: center;" *ngIf="_pagination">
                                <pagination-controls id="listing_paginated" (pageChange)="p = $event" maxSize="5" directionLinks="true" autoHide="true" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                            </div>
                        </div>
                        <!-- end table-responsive-->

                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>
