<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/main/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/main/user-system']">Utlisateur</a></li>
                                <li class="breadcrumb-item active">Profil Utilisateur</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">

                <div class="col-lg-12">
                    <div>


                        <!-- <h4 class="header-title">INFORMATIONS UTILISATEURS</h4> -->
                        <p class="sub-header">
                            Mes Informations Personnelles
                        </p>

                        <div class="table-responsive">
                            <div class="col-lg-6 col-xl-6 offset-3">
                                <div class="card-box text-center">
                                    <img src="../../../assets/images/users/user-1.png" class="rounded-circle avatar-lg img-thumbnail" alt="profile-image"> <br>

                                    <h4 class="mb-0">{{name | uppercase}}</h4> <span class="small" style="color: green; font-weight: bold;">Actif</span>
                                    <p class="text-muted mb-1"> <span class="ml-2"> {{profile}} </span></p>

                                    <div class="text-left mt-3" style="font-size: 25px; text-align: justify;">
                                        <p class="text-muted mb-2"><strong>Nom :</strong> <span class="ml-2">{{name | uppercase}}</span></p>

                                        <p class="text-muted mb-2"><strong>Prénom :</strong> <span class="ml-2"> {{prenom | lowercase}} </span></p>

                                        <p class="text-muted mb-2"><strong>Contact :</strong><span class="ml-2">(00229)&nbsp;{{contact}}</span></p>

                                        <p class="text-muted mb-2"><strong>Email :</strong> <span class="ml-2 ">{{email}}</span></p>
                                    </div>
                                    <br />
                                    <button type="button" (click)="open({option: 'ADD'})" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD', 'EFBP_PRD_ASS_PROJ', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_ADM_FUNCTIONAL', 'EFBP_PRD_ADM_DEVOPS']" class="btn btn-outline-warning btn-rounded waves-effect waves-light float-right">
                                        Réinitialiser son mot de passe
                                    </button> <br />
                                </div>
                                <!-- end card-box -->

                            </div>
                            <!-- end col-->
                        </div>
                        <!-- end table-responsive-->

                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->
