import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

import { AppComponent } from './app.component';
import { MaterialModule } from './shared/materials/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_ROUTES } from './app.routing.module';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './entriesComponent/login/login.component';
import { ErrorComponent } from './entriesComponent/error/error.component';
import { ApiProviders } from './shared/apiProviders/api-providers.service';
import { AuthGuardService } from './shared/apiProviders/auth-guard.service';
import { AuthService } from './shared/apiProviders/auth.service';
import { DataProvider } from './shared/apiProviders/data-providers';
import { DialogProvider } from './shared/apiProviders/dialog.provider';
import { EditDialogService } from './shared/apiProviders/dialogconfig';
import { EditDialogStatService } from './shared/apiProviders/dialogStatConfig';
import { ToastrModule } from 'ngx-toastr';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { SharedModule } from './shared/shared.module';
import { CustomDirective } from './shared/apiProviders/custom.directive';
// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';
import { MainModule } from './pagesComponent/main.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#9BE991',
  bgsOpacity: 0.7,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 3,
  delay: 2,
  fastFadeOut: true,
  fgsColor: '#4ee33a',
  fgsPosition: 'center-center',
  fgsSize: 80,
  fgsType: 'three-strings',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
 //  logoUrl: 'assets/images/logo_andf.jpg',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#38df6a',
  pbDirection: 'ltr',
  pbThickness: 2,
  hasProgressBar: true,
 //  text: "Bienvenue Sur Le Système D'Information Du Foncier",
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 200
};

@NgModule({
  declarations: [AppComponent, LoginComponent, ErrorComponent, CustomDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    MainModule,
    SharedModule,
    ProgressbarModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxMatSelectSearchModule,
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot(APP_ROUTES, {
      useHash: false,
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    // { provide: LocationStrategy, useClass: HashLocationStrategy},
    ApiProviders,
    AuthGuardService,
    AuthService,
    // DatePipe,
    DataProvider,
    DialogProvider,
    EditDialogService,
    EditDialogStatService
  ],
  bootstrap: [AppComponent],
  schemas: []
})
export class AppModule {}

