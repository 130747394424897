import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '../shared/materials/material.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LAYOUT_ROUTES } from './main.routing';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MainPageComponent } from './main-page/main-page.component';
import { FooterPageComponent } from './footer-page/footer-page.component';
import { FieldDataUploadComponent } from './field-data-upload/field-data-upload.component';
import { ParameterComponent } from './parameter/parameter.component';
import { UpdateParamaterComponent } from './parameter/update-paramater/update-paramater.component';
import { PostObservationComponent } from './field-data-upload/post-observation/post-observation.component';
import { DataCompareComponent } from './data-compare/data-compare.component';
import { ProgressBarComponent } from './field-data-upload/progress-bar/progress-bar.component';
import { ApproveFileComponent } from './field-data-upload/approve-file/approve-file.component';
import { ApprovalComponent } from './main-header/approval/approval.component';
import { PageUserComponent } from './page-user/page-user.component';
import { AddEditPageUserComponent } from './page-user/add-edit-page-user/add-edit-page-user.component';
import { ApprovalDataComponent } from './field-data-upload/approve-file/approval-data/approval-data.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AddEditUploadFileComponent } from './field-data-upload/add-edit-upload-file/add-edit-upload-file.component';
import {NgSimpleProgressBarModule} from 'ng-simple-progress-bar';
import { UserProfileInfoComponent } from './user-profile-info/user-profile-info.component';
import { PwdFormComponent } from './user-profile-info/pwd-form/pwd-form.component';
import { PdfRepportComponent } from './field-data-upload/pdf-repport/pdf-repport.component';
import { PdfCadatralMapComponent } from './field-data-upload/pdf-cadatral-map/pdf-cadatral-map.component';
import { PdfRertoireComponent } from './field-data-upload/pdf-rertoire/pdf-rertoire.component';
import { PdfRepertoireComponent } from './field-data-upload/pdf-repertoire/pdf-repertoire.component';
import { ActiveDesacUserComponent } from './page-user/active-desac-user/active-desac-user.component';
import { UserPwdFormComponent } from './page-user/pwd-form/pwd-form.component';
import { AffectProfileComponent } from './page-user/affect-profile/affect-profile.component';
import { PasswordStrengthBarComponent } from './page-user/password-strength-bar/password-strength-bar.component';
import { CheckInputComponent } from './field-data-upload/check-input/check-input.component';
import { FileCompareComponent } from './field-data-upload/file-compare/file-compare.component';
import { InputValidationComponent } from './field-data-upload/check-input/input-validation/input-validation.component';
import { SharedfileComponent } from './field-data-upload/sharedfile/sharedfile.component';
import { SearchPageComponent } from './main-header/search-page/search-page.component';
import { GlobalApproveComponent } from './field-data-upload/approve-file/global-approve/global-approve.component';
import { SortDocComponent } from './field-data-upload/sort-doc/sort-doc.component';
import { ValidateChoiceComponent } from './field-data-upload/approve-file/validate-choice/validate-choice.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';

registerLocaleData(localeFr, 'fr');

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#9BE991',
  bgsOpacity: 0.7,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 3,
  delay: 2,
  fastFadeOut: true,
  fgsColor: '#4ee33a',
  fgsPosition: 'center-center',
  fgsSize: 80,
  fgsType: 'three-strings',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
 //  logoUrl: 'assets/images/logo_andf.jpg',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#38df6a',
  pbDirection: 'ltr',
  pbThickness: 2,
  hasProgressBar: true,
 //  text: "Bienvenue Sur Le Système D'Information Du Foncier",
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 200
};


const entryComponents = [
  UpdateParamaterComponent,
  PostObservationComponent,
  ApprovalComponent,
  DataCompareComponent,
  AddEditPageUserComponent
];

@NgModule({
  declarations: [
    MainHeaderComponent,
    MainPageComponent,
    FooterPageComponent,
    ParameterComponent,
    UpdateParamaterComponent,
    FieldDataUploadComponent,
    PostObservationComponent,
    DataCompareComponent,
    ProgressBarComponent,
    ApproveFileComponent,
    ApprovalComponent,
    PageUserComponent,
    AddEditPageUserComponent,
    ApprovalDataComponent,
    AddEditUploadFileComponent,
    UserProfileInfoComponent,
    PwdFormComponent,
    PdfRepportComponent,
    PdfCadatralMapComponent,
    PdfRertoireComponent,
    PdfRepertoireComponent,
    ActiveDesacUserComponent,
    UserPwdFormComponent,
    AffectProfileComponent,
    PasswordStrengthBarComponent,
    CheckInputComponent,
    FileCompareComponent,
    InputValidationComponent,
    SharedfileComponent,
    SearchPageComponent,
    GlobalApproveComponent,
    SortDocComponent,
    ValidateChoiceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PdfViewerModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgSimpleProgressBarModule,
    NgxExtendedPdfViewerModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    RouterModule.forChild(LAYOUT_ROUTES)
  ],
  entryComponents: [
    ...entryComponents,
  ],
  exports: [],
  providers: [ DatePipe ]
})
export class MainModule { }
