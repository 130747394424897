<body class="auth-fluid-pages pb-0">

    <div class="auth-fluid">

        <!-- Auth fluid right content -->
        <div class="auth-fluid-right text-center">
            <div class="auth-user-testimonial">
                <h2 class="mb-3 text-white">E-FONCIER BENIN</h2>
                <p class="lead" style="text-align: justify;"><i class="mdi mdi-format-quote-open"></i> Dans le souci de préserver l’intégrité de son territoire et de garantir les droits de propriété acquis suivant les lois et règlements ainsi que les règles coutumières par les personnes physiques
                    et morales de droit public ou privé, l’Etat béninois a entrepris une réforme foncière dont l’un des premiers jalons est la loi n° 2013-01 du 14 août 2013 portant Code Foncier et Domanial (CFD) en République du Bénin. Ladite loi institue,
                    en ses articles 452 et suivants, le cadastre ainsi que les règles qui le régissent.
                    <i class="mdi mdi-format-quote-close"></i>
                </p>
                <h5 class="text-white">
                    - EFB &copy; 2021
                </h5>
            </div>
            <!-- end auth-user-testimonial-->
        </div>
        <!-- end Auth fluid right content -->
        <!--Auth fluid left content -->
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex h-100">
                <div class="card-body"> <br /><br /><br />
                    <!-- Logo -->
                    <!-- <div class="form-group" style="width: 100%; text-align: center;">
                        <mat-radio-group style="justify-content:space-between" aria-label="Select an option" name="choice" [(ngModel)]="version" (change)="changeVersion(version)">
                            <mat-radio-button value="9091" color="primary">TEST</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="9090" color="primary" [checked]="true">DEV</mat-radio-button> &nbsp;&nbsp;
                        </mat-radio-group>
                    </div> -->
                    <div class="auth-brand text-center text-lg-left">
                        <div class="auth-logo">
                            <a href="index.html" class="logo logo-dark text-center">
                                <span class="logo-lg">
                                  <img src="assets/images/logo_andf.jpg" alt="" height="150">
                              </span>
                            </a>

                            <a href="index.html" class="logo logo-light text-center">
                                <span class="logo-lg">
                                  <img src="assets/images/logo_andf.jpg" alt="" height="50">
                              </span>
                            </a>
                        </div>
                    </div>

                    <!-- title-->
                    <h4 class="mt-0">Identification</h4> <br>
                    <!-- <p class="text-muted mb-4">Enter your email address and password to access account.</p> -->

                    <!-- form -->
                    <form #loginForm="ngForm" role="form" (ngSubmit)="save()">
                        <div class="form-group">
                            <label for="emailaddress">Nom d'utilistateur</label>
                            <input class="form-control" type="text" id="username" [(ngModel)]="user.username" name="username" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required="" placeholder="Nom d'utilisateur">
                        </div>
                        <div class="form-group">
                            <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
                            <label for="password">Mot de passe</label>
                            <div class="input-group input-group-merge">
                                <input [type]="hide ? 'password' : 'text'" id="password" [(ngModel)]="user.password" name="password" class="form-control" placeholder="Mot de passe">
                                <div class="input-group-append" data-password="false">
                                    <div class="input-group-text" (click)="hide = !hide" >
                                        <i attr.class="{{hide ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="form-group mb-0 text-center">
                            <button id="connect" class="btn btn-success btn-block" type="submit">
                              <span *ngIf="_progress" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                                 Connexion
                            </button>
                        </div>
                    </form>
                    <!-- end form-->

                    <!-- Footer-->
                    <footer class="footer footer-alt">
                        <p class="text-muted">AGENCE NATIONALE DU DOMAINE ET DU FONCIER</p>
                    </footer>

                </div>
                <!-- end .card-body -->
            </div>
            <!-- end .align-items-center.d-flex.h-100-->
        </div>
        <!-- end auth-fluid-form-box-->
    </div>
    <!-- end auth-fluid-->


</body>