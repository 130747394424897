<body data-layout-mode="horizontal" data-layout='{"mode": "light", "width": "fluid", "menuPosition": "fixed", "topbar": {"color": "dark"}, "showRightSidebarOnPageLoad": true}'>

    <!-- Begin page -->
    <div id="wrapper">

        <!-- Topbar Start -->
        <div class="navbar-custom">
            <div class="container-fluid">
                <ul class="list-unstyled topnav-menu float-right mb-0">

                    <!-- <li class="dropdown d-none d-lg-inline-block">
                        <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" (click)="search('SEARCH')">
                            <i class="fe-search noti-icon"></i>
                        </a>
                    </li> -->

                    <li class="dropdown d-none d-lg-inline-block">
                        <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="fullscreen" href="#">
                            <i class="fe-maximize noti-icon"></i>
                        </a>
                    </li>


                    <li class="dropdown notification-list topbar-dropdown">
                        <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                            <!-- <img src="../assets/images/users/user-1.jpg" alt="user-image" class="rounded-circle"> -->
                            <span class="pro-user-name ml-1" data-toggle="tooltip" data-placement="bottom" title="">
                              {{name | uppercase}} <i class="mdi mdi-chevron-down"></i>
                        </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                            <!-- item-->
                            <div class="dropdown-header noti-title">
                                <h6 class="text-overflow m-0">Bienvenue !</h6>
                            </div>

                            <!-- item-->
                            <a [routerLink]="['/procedure-d-enregistrement-collectif/profile-user']" *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE','EFBP_PRD_ADM_DEVOPS' ,'EFBP_PRD', 'EFBP_PRD_ASS_PROJ', 'EFBP_PRD_INSP_GEO', 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_ADM_FUNCTIONAL']" class="dropdown-item notify-item">
                                <i class="fe-user"></i>
                                <span>Mon Profil</span>
                            </a>

                            <div class="dropdown-divider"></div>

                            <!-- item-->
                            <a (click)="deconnexion()" class="dropdown-item notify-item">
                                <i class="fe-log-out"></i>
                                <span>Sortir</span>
                            </a>

                            <!-- item-->
                            <!-- <a class="dropdown-item notify-item">
                                <i class="fe-lock"></i>
                                <span>Vérouiller</span>
                            </a> -->

                            <!-- <div class="dropdown-divider"></div> -->

                            <!-- item-->
                            <!-- <a class="dropdown-item notify-item">
                                <i class="fe-log-out"></i>
                                <span style="font-weight: bold;"> DEV (version 1.0.2) </span>
                            </a> -->
                        </div>
                    </li>

                    <!-- <li class="dropdown notification-list">
                        <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light">
                            <i class="fe-settings noti-icon"></i>
                        </a>
                    </li> -->

                </ul>

                <!-- LOGO -->
                <div class="logo-box">
                    <a [routerLink]="['/procedure-d-enregistrement-collectif/statistique']" class="logo logo-dark text-center" style="margin-top: 6px;">
                        <span class="logo-sm">
                        <img src="assets/images/logo_andf.jpg" alt="" height="50">
                        <!-- <span class="logo-lg-text-light">UBold</span> -->
                        </span>
                        <span class="logo-lg">
                        <img src="assets/images/logo_andf.jpg" alt="" height="100" class="d-flex mr-2 rounded-circle">
                        <!-- <span class="logo-lg-text-light">U</span> -->
                        </span>
                    </a>

                    <a [routerLink]="['/procedure-d-enregistrement-collectif/statistique']" class="logo logo-light text-center" style="margin-top: 6px;">
                        <span class="logo-sm">
                        <img src="assets/images/logo-sm.png" alt="" height="50" class="d-flex mr-2 rounded-circle">
                    </span>
                        <span class="logo-lg">
                        <img src="assets/images/logo_andf.jpg" alt="" height="60" class="d-flex mr-2 rounded-circle">
                    </span>
                    </a>
                </div>

                <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
                    <li>
                        <button class="button-menu-mobile waves-effect waves-light">
                        <i class="fe-menu"></i>
                    </button>
                    </li>

                    <li>
                        <!-- Mobile menu toggle (Horizontal Layout)-->
                        <a class="navbar-toggle nav-link" data-toggle="collapse" data-target="#topnav-menu-content">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        <!-- End mobile menu toggle-->
                    </li>

                </ul>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- end Topbar -->

        <div class="topnav shadow-lg">
            <div class="container-fluid">
                <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

                    <div class="collapse navbar-collapse" id="topnav-menu-content">
                        <ul class="navbar-nav">
                            <li *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD', 'EFBP_PRD_ASS_PROJ',  'EFBP_PRD_INSP_GEO', 'EFBP_PRD_DIR_CADASTRAL',  'EFBP_PRD_ADM_FUNCTIONAL']" class="nav-item">
                                <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/procedure-d-enregistrement-collectif/statistique']" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fe-home mr-1"></i> Tableau de bord
                                    <!-- <div class="arrow-down"></div> -->
                                </a>
                            </li>

                            <li *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD', 'EFBP_PRD_DIR_CADASTRAL', 'EFBP_PRD_ASS_PROJ',  'EFBP_PRD_INSP_GEO','EFBP_PRD_ADM_FUNCTIONAL']" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/procedure-d-enregistrement-collectif/enregistrement']" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fe-database mr-1"></i> Données
                                    <!-- <div class="arrow-down"></div> -->
                                </a>
                            </li>

                            <!-- <li *ngxPermissionsOnly="['EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle arrow-none" (click)="open('CALLED')" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fe-server mr-1"></i> Approbations
                                    <div class="arrow-down"></div>
                                </a>
                            </li> -->

                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle arrow-none" [routerLink]="['/main/parameter']" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fe-settings mr-1"></i> Paramètres
                                    <div class="arrow-down"></div>
                                </a>
                            </li> -->

                            <li *ngxPermissionsOnly="['EFBP_PRD_ADM_FUNCTIONAL']" class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-components" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fe-settings mr-1"></i> Configurations
                                    <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="topnav-components">
                                    <a [routerLink]="['/procedure-d-enregistrement-collectif/parameter']" class="dropdown-item"><i class="fe-globe mr-1"></i> Paramètrage</a>
                                    <a [routerLink]="['/procedure-d-enregistrement-collectif/user-system']" class="dropdown-item"><i class="fe-user mr-1"></i> Utilisateur</a>
                                    <!-- <a href="http://172.17.0.70:9092" class="dropdown-item"><i class="fe-link mr-1"></i> Prometheus</a> -->
                                    <a href="http://172.17.0.70:3000" target="_blank" class="dropdown-item"><i class="fe-link mr-1"></i> Supervision</a>
                                    <!-- <a href="http://172.17.0.70:9093" class="dropdown-item"><i class="fe-link mr-1"></i> Gestion des alertes</a> -->
                                </div>
                            </li>

                        </ul>
                        <!-- end navbar-->


                    </div>
                    <!-- end .collapsed-->
                </nav>
            </div>
            <!-- end container-fluid -->
        </div>
        <!-- end topnav-->

        <router-outlet></router-outlet>

    </div>
    <!-- END wrapper -->

</body>