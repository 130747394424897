<h4 class="header-title"> {{formTitle}} </h4>
<p class="sub-header text-danger">
    Ajouter un profil supplémentaire*
</p>

<div class="row">
    <div class="col-lg-12">
        <form #userAddForm="ngForm" name="uploadForm" (ngSubmit)="save()">

            <div mat-dialog-content>

                <div class="form-group mb-3">
                    <label for="subgroup" class="text-dark">Profil &nbsp;<span class="text-danger">*</span> </label>
                    <select class="form-control" [(ngModel)]="profile" name="profile" id="profile" style="font-size: 15px;">
                      <option value="selected">Sélectionner</option>
                      <option *ngFor="let data of donnee" value="{{ data.gidNumber }}">{{ data.description }}</option>
                    </select>
                </div>
            </div>

            <mat-divider></mat-divider> <br />

            <div style="float: right;">
                <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close> Annuler </button> &nbsp;
                <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="submit">
                <span>
                  <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                  <!-- <i class="mdi mdi-database-plus"></i> -->
                  {{btnAction}}
                </span>
              </button>
            </div>
        </form>
    </div>
    <!-- end col -->
</div>
<!-- end row-->
