<h4 style="text-align: center;">
    FORMULAIRE DE COMPARAISON</h4>
<form #approvalForm="ngForm" name="observationForm" (ngSubmit)="save()">


    <div class="form-group">
        <label for="example-date1" style="color: black;">Ancien fichier &nbsp;<span class="text-danger">*</span></label>
        <input class="form-control" id="example-date1" type="text" name="codep" [(ngModel)]="oldfile" required>
        <!-- <small class="text-danger" *ngIf="verify"> Saisir le code du projet pour approbation</small> -->
    </div>
    <div class="form-group">
        <label for="example-date1" style="color: black;">Nouveau fichier &nbsp;<span class="text-danger">*</span></label>
        <input class="form-control" id="example-date1" type="text" name="codep" [(ngModel)]="newfile" required>
        <!-- <small class="text-danger" *ngIf="verify"> Saisir le code du projet pour approbation</small> -->
    </div>



    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
      Annuler
    </button> &nbsp;
        <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="submit">
      <span>
        <i class="mdi mdi-database-edit"></i>
        Envoyer
      </span>
    </button>
    </div>

</form>