<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/main/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/main/user-system']">Administarion</a></li>
                                <li class="breadcrumb-item active">Gestion des accès</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">

                <div class="col-lg-12">
                    <div class="card-box">


                        <div class="dropdown float-right">

                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-outline-success btn-rounded waves-effect waves-light"
                                        (click)="open({option: 'ADD'})">
                                        <i class="mdi mdi-upload-outline"></i>
                                        Ajouter
                                    </button>
                                </div>
                            </div>
                        </div>


                        <h4 class="header-title">GESTION UTILISATEURS</h4>
                        <p class="sub-header">
                            Liste des utilisateurs
                        </p>
                        <div class="dropdown float-right">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="example-select">Rechercher Par</label>
                                    <input type="text" class="form-control" id="field-1" placeholder="Nom Prénom ou Contact " name="keySearch" [(ngModel)]="keySearch" required (keyup)="retrieveAlldatas()" >
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-borderless table-hover table-nowrap table-centered m-0" style="width: 100%;">

                                <thead class="thead-light">
                                    <tr>
                                        <th>Nom & Prénom </th>
                                        <th style="text-align: justify; width: 150px !important; ">Organisatoion</th>
                                        <th style="text-align: justify; width: 150px !important; ">Profil</th>
                                        <th>E-mail</th>
                                        <th>Contact</th>
                                        <!-- <th>Enregistré le</th> -->
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style="font-weight: bold;" *ngFor="let data of datas | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRec }">
                                        <!-- <td style="width: 36px;">
                                            <img src="assets/images/users/user-2.jpg" alt="contact-img" title="contact-img" class="rounded-circle avatar-sm" />
                                        </td> -->

                                        <td>
                                            <h5 class="m-0 font-weight-normal">{{data.fullName}} &nbsp; {{data.lastName}}</h5>
                                            <!-- <p class="mb-0 text-muted"><small> {{data.mail}} </small></p> -->
                                        </td>

                                        <td>
                                            {{data.organisation}}
                                        </td>

                                        <td style="word-wrap: break-word; width: 450px;">
                                            {{data.profil}}
                                        </td>

                                        <td>
                                            {{data.mail}}
                                        </td>

                                        <td>
                                            {{data.phone}}
                                        </td>

                                        <!-- <td>
                                            0.00097036 BTC
                                        </td> -->

                                        <td style=" width: 150px; display: inline-block; align-items: center;">
                                                <div class="dropdown" style="margin-top: 10px;" >
                                                    <a href="#"  class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i class="mdi mdi-dots-vertical"></i>
                                                        <span class="small text-dark"></span>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right" >

                                                        <a style="font-weight: bold; color: #000; font-size: 14px;" class="btn btn-xs btn-light dropdown-item" (click)="check({option: 'ACTIVATION', data: data})">
                                                            <span style="font-weight: bold;" [ngStyle]="{'color': data.status === 'ACTIVE' ? '#3f37c9' : (data.status === 'SUSPENDED' ? '#c93743' : null) }">
                                                                <i class="mdi mdi-account-lock-outline"></i>
                                                              {{data.status === 'ACTIVE' ? 'DESACTIVER' : 'ACTIVER' }}
                                                            </span>
                                                        </a>&nbsp;
                                                        <a  style="font-weight: bold; color: #000; font-size: 14px;" class="btn btn-xs btn-success dropdown-item" (click)="affect({option: 'AFFECTER', data: data})">
                                                            <i class="mdi mdi-account-multiple-plus-outline"></i>
                                                            Affecter Profil
                                                        </a>&nbsp;
                                                        <a style="font-weight: bold; color: #000; font-size: 14px;" class="btn btn-xs btn-success dropdown-item" (click)="open({option: 'EDIT', data: data})">
                                                            <i class="mdi mdi-account-edit"></i>
                                                            Modifier infos user
                                                        </a>&nbsp;
                                                        <a style="font-weight: bold; color: #000; font-size: 14px;" class="btn btn-xs btn-warning dropdown-item" (click)="pwd({option: 'PWD-REBOOT', data: data})">
                                                            <i class="mdi mdi-account-key"></i>
                                                            Réinitialiser mot de passe
                                                        </a>
                                                    </div>
                                                </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div style="text-align: center;">
                                <pagination-controls id="listing_paginated" (pageChange)="p = $event" maxSize="5" directionLinks="true" autoHide="true" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                            </div>
                        </div>
                        <!-- end table-responsive-->

                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->
