import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { Validations } from 'src/app/shared/models/observation';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.css']
})
export class InputValidationComponent implements OnInit {
  model = new Validations(); choix: string; optionalInput1 = false;
  progress = false;  lineBar = false; validate = 'Envoyer';
  msg = '';
  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InputValidationComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

    // tslint:disable-next-line: typedef
    thechoice() {
      console.log(this.choix);
      if (this.choix === 'valider' ) {
        this.optionalInput1  = false;
        // this.optionalInput2  = false;
      } else if (this.choix === 'rejetter') {
        this.optionalInput1  = true;
        // this.optionalInput2  = false;
      }
    }

    save() {
      this.validate = 'Patienter';
      console.log(this.model);
      if (!this.choix) {
        Swal.fire({
          type: 'error',
          text: 'Quelle action aimeriez vous exécuter..?',
          title: 'VALIDER ou REJETER ???'
        });
      } else if (this.choix === 'rejetter' && !this.model.note) {
        this.msg = 'Ce champ est obligatoire';
      } else if (this.choix === 'valider') {
        this.model.status = "CONTINU",
        this.model.note = "Pas d'observation",
        this.lineBar = true;
        this.api.checkInputValidation(this.data, this.model).subscribe(
          (resp: any) => {
            this.validate = 'OK';
            console.log(resp);
            console.log(resp.status);
            this.lineBar = false;
            this.dialogRef.close();
            Swal.fire({
              type: 'success',
              text: resp.message,
            });
          },
          (error) => {
            console.log(error);
            this.lineBar = false;
            this.dialogRef.close();
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });
          }
        );
      } else { 
        this.model.status = "REJETTED",
        this.lineBar = true;
        this.api.checkInputValidation(this.data, this.model).subscribe(
          (resp: any) => {
            this.validate = 'OK';
            console.log(resp);
            console.log(resp.status);
            this.lineBar = false;
            this.dialogRef.close();
            Swal.fire({
              type: 'success',
              text: resp.message,
            });
          },
          (error) => {
            console.log(error);
            this.lineBar = false;
            this.dialogRef.close();
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });
          }
        );
      }
    }
}
