import { Routes } from '@angular/router';
import { ErrorComponent } from './entriesComponent/error/error.component';
import { LoginComponent } from './entriesComponent/login/login.component';
import { AuthGuardService } from './shared/apiProviders/auth-guard.service';

export const APP_ROUTES: Routes = [

  { path: 'Accueil', component: LoginComponent},
  {
    path: '',
    redirectTo: 'Accueil',
    pathMatch: 'full',
    // canActivate: [AuthGuardService]
  },
  // { path: 'login', component: LoginComponent }
  { path: '**', component: ErrorComponent },
];
