import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import Swal from 'sweetalert2';
import { ApprovalComponent } from './approval/approval.component';
import jwt_decode from 'jwt-decode';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiProviders } from '../../shared/apiProviders/api-providers.service';
import { SearchPageComponent } from './search-page/search-page.component';


declare let general: any;
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css'],
})
export class MainHeaderComponent implements OnInit, AfterViewInit {
  username: string; perm: any[] = []; progress = false;
  decoded = jwt_decode(this.Token()); 
  datas; name; prenom; email; contact; profile; role;
  _editDialogRef: MatDialogRef<ApprovalComponent>;

  constructor(
    private api: ApiProviders,
    private router: Router,
    public dialog: MatDialog,
    private permission: NgxPermissionsService,
    private editDialogService: EditDialogService<ApprovalComponent>,
    ) {}

  ngOnInit(): void {
    this.usersInfo();
    localStorage.setItem('789', JSON.stringify(this.decoded));
    console.log(this.role);
    this.username = this.User();
    // console.log(this.decoded);
    // console.log(this.ress());
    console.log(this.ress().roles);
    console.log(this.permission.loadPermissions(this.ress().roles));
  }

  ngAfterViewInit(): void {
    general();
  }

    async usersInfo() {
      this.progress = true;
      await this.api.getUserInformation(this.user()).subscribe((res: any) => {
        this.progress = false;
        console.log(res);
        console.log(res.object);
        this.datas = res.object;
        this.name = this.datas.fullName;
        this.email = this.datas.mail;
        this.profile = this.datas.profil;
      }, (error) => {
        console.log(error);
        this.progress = false;
      }
      );
    }


  user() {
    const User = window.localStorage.getItem('username');
    return User;
  }
  deconnexion(): void {
    Swal.fire({
      title: 'Etes vous sûr?',
      text: 'de vouloir vous déconnecter..!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1DD0D9',
      cancelButtonColor: '#F7A47A',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/Accueil']);
      }
    });
  }


  User() {
    const User = window.localStorage.getItem('username');
    return User;
  }

  open(data): void {
    this._editDialogRef = this.editDialogService.open( ApprovalComponent, { data });
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe((result) => {
      // this.retrieveAlldatas();
    });
  }

  search(data): void {
    
    const dialogRef = this.dialog.open(SearchPageComponent, {
      // disableClose: true,
      width: '650px',
      position: {top: '5%', left: '35%'},
      
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.retrieveAlldatas();
    });
  }


  // tslint:disable-next-line:typedef
  Token() {
    const Token = window.localStorage.getItem('token');
    return Token;
  }

  // tslint:disable-next-line:typedef
  ress() {
    const r =  JSON.parse(window.localStorage.getItem('789'));
    return r;
  }
}
