import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
  Token() {
    const Token = window.localStorage.getItem('token');
    return Token;
  }


  constructor(private _router: Router, private _authService: AuthService) {
  }

  canActivate(): boolean {
    if (!this.Token()) {
      this._router.navigate(['login']);
      return false;
    }
    return true;
  }

}
