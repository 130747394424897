import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {saveAs as importedSaveAs} from "file-saver";

@Component({
  selector: 'app-pdf-repertoire',
  templateUrl: './pdf-repertoire.component.html',
  styleUrls: ['./pdf-repertoire.component.css']
})
export class PdfRepertoireComponent implements OnInit {
  @ViewChild('iframeRepertoire') iframe: ElementRef;
  progress = false; choice; param: boolean;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiProviders
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  lechoix() {
    if (this.choice === 'OUI') {
      this.api.checkGeojsonFilePublication(this.data.data.filename , true).subscribe((resultBlob: Blob) => {
          importedSaveAs(resultBlob, this.data.data.filename + ".pdf");
      }); 

    }else if(this.choice === 'NON') {
      this.api.checkGeojsonFilePublication(this.data.data.filename, false).subscribe((resultBlob: Blob) => {
      importedSaveAs(resultBlob, this.data.data.filename + ".pdf");
      }); 
    }
  }



}
