import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-pwd-form',
  templateUrl: './pwd-form.component.html',
  styleUrls: ['./pwd-form.component.css']
})
export class UserPwdFormComponent implements OnInit {
  hide = true; hiden = true; hidenn = true; username;
  variable; labelActive; btnActive; progress; pwdU;
  model = new User();
    constructor(
      // tslint:disable-next-line:variable-name
      private api: ApiProviders,
      private toast: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<UserPwdFormComponent>
    ) { }

    ngOnInit(): void {
      console.log(this.data);
      console.log(this.data.data);
      console.log(this.data.data.status);
      this.username = this.data.data.username;
    }

    // tslint:disable-next-line:typedef
    updatePwd() {
      this.progress = true;
      if (this.pwdU !== this.model.password) {
        this.toast.info('Les mots de passe ne sont pas identiques');
      } else {
        this.model.username = this.data.data.username;
        // tslint:disable-next-line:max-line-length
        this.api.changePwd(this.model).subscribe(
          (resp: any) => {
            this.progress = false;
            console.log(resp);
            this.toast.success('Mot de passe modifié avec succès');
            this.dialogRef.close();
          }, (error) => {
            console.log(error);
            this.toast.success(error.error.message);
          }
        );
      }
    }
}
