<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/main/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/main/parameter']">Vérification</a></li>
                                <li class="breadcrumb-item active">Vérification du fichier GeoJSON</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">
                <div class="col-lg-12">
                    <div class="card-box">
                        <div class="dropdown float-right" style="margin-left: 10px;">
                            <button type="button" class="btn btn-light waves-effect waves-light">
                                <a (click)="open({option: 'CHECK', data: data})" style="color: black">
                                    <i class="mdi mdi-check-bold"></i>
                                    Observation
                                </a>
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" class="btn btn-danger waves-effect waves-light">
                                <a [routerLink]="['/main/upload-data']" style="color: white">
                                    <i class="mdi mdi-chevron-double-left"></i>
                                    Retour
                                </a>
                            </button>
                        </div>


                        <h4 class="header-title">LISTE DES ATTRIBUTS NON RENSEIGNES</h4>
                        <p class="sub-header">
                            Comparaison
                        </p>
                        <div class="table-responsive">
                            <table class="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th>Parcelles N°</th>
                                        <th colspan="6" style="text-align: center">Attributs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let datas of data">
                                        <td><strong>{{datas.parcel}} </strong></td>
                                        <td style="text-align: center" *ngFor="let att of datas.attributes">
                                            <tr style="background-color: #e0e0e0 ; font-weight: bold;">
                                                <span>{{att}}</span>
                                            </tr>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->