<h3 style="text-align: center; font-weight: bold;">{{labelActive}}</h3>


<mat-divider></mat-divider> <br />

<div style="float: right;">
    <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
    Annuler
  </button> &nbsp;
    <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="button" (click)="activeOrdesactive()">
    <span>
      <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
      <!-- <i class="mdi mdi-database-plus"></i> -->
      {{btnActive}}
    </span>
  </button>
</div>
