import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
 verify = false; codep;
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApprovalComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    console.log(this.data.data)
    console.log(this.data.data.data)
    this.codep = this.data.data.data.projectCode
  }

  save(): void {
    if (!this.codep) {
      this.verify = true;
    } else {
      this.dialogRef.close();
      this.router.navigate(['main/approve', this.codep ]);
    }
  }
}
