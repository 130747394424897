import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import Swal from 'sweetalert2';
import { ActiveDesacUserComponent } from './active-desac-user/active-desac-user.component';
import { AddEditPageUserComponent } from './add-edit-page-user/add-edit-page-user.component';
import { AffectProfileComponent } from './affect-profile/affect-profile.component';
import { UserPwdFormComponent } from './pwd-form/pwd-form.component';

@Component({
  selector: 'app-page-user',
  templateUrl: './page-user.component.html',
  styleUrls: ['./page-user.component.css']
})
export class PageUserComponent implements OnInit {

  progress = false; datas: any[] = []; totalRec; p = 1; keySearch: any;
  editDialogRef: MatDialogRef<AddEditPageUserComponent>;
  constructor(
    private api: ApiProviders,
    private toast: ToastrService,
    public dialog: MatDialog,
    private editDialogService: EditDialogService<AddEditPageUserComponent>
  ) { }

  ngOnInit(): void {
    this.retrieveAlldatas();
  }

  // tslint:disable-next-line:typedef
  async retrieveAlldatas() {
    this.progress = true;
    await this.api.getUserList().subscribe((res: any) => {
      this.progress = false;
      console.log(res);
      this.datas = res.body.content;
      this.totalRec = res.body.content.length;
      console.log(this.totalRec);

    }, (error) => {
      console.log(error);
      this.progress = false;
    }
    );
  }

  open(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(AddEditPageUserComponent, { disableClose: true, width: '700px', data: data });
    dialogRef.afterClosed().subscribe(result => {
      this.retrieveAlldatas();
    });
  }

  check(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(ActiveDesacUserComponent, { disableClose: true, width: '500px', data: data });
    dialogRef.afterClosed().subscribe(result => {
      this.retrieveAlldatas();
    });
  }

  pwd(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(UserPwdFormComponent, { disableClose: true, width: '500px', data: data });
    dialogRef.afterClosed().subscribe(result => {
      this.retrieveAlldatas();
    });
  }

  affect(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(AffectProfileComponent, { disableClose: true, width: '500px', data: data });
    dialogRef.afterClosed().subscribe(result => {
      this.retrieveAlldatas();
    });
  }

}
