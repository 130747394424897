import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';

@Component({
  selector: 'app-file-compare',
  templateUrl: './file-compare.component.html',
  styleUrls: ['./file-compare.component.css']
})
export class FileCompareComponent implements OnInit {

  oldfile; newfile;

  constructor(
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileCompareComponent>
  ) {}

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  save() {}
}
