import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import { UpdatePath } from 'src/app/shared/models/updatePath';
import { UpdateParamaterComponent } from './update-paramater/update-paramater.component';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.css']
})
export class ParameterComponent implements OnInit {
  color = 'accent';  model = new UpdatePath(); type: string;
  mode = 'query';
  datas: any[] = [];
  q: any;
  p = 1; totalRec: number;
  // tslint:disable-next-line:variable-name
  _editDialogRef: MatDialogRef<UpdateParamaterComponent>;
  // tslint:disable-next-line:variable-name
  _progress = false;

  constructor(
    // tslint:disable-next-line:variable-name
    private _api: ApiProviders,
    // tslint:disable-next-line:variable-name
    public _dialog: MatDialog,
    // tslint:disable-next-line:variable-name
    private _editDialogService: EditDialogService<UpdateParamaterComponent>
  ) { }

  ngOnInit(): void {
    this.type = 'MAIL';
    this.retrieveAlldatas();
  }

  edit(data): void {
    this._editDialogRef = this._editDialogService.open(UpdateParamaterComponent, { data });
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe(result => {
      this.retrieveAlldatas();
    });
  }

  // tslint:disable-next-line:typedef
  async retrieveAlldatas() {
    this._progress = true;
    await this._api.listParamAdminConfig(this.type).subscribe((res: any) => {
      this._progress = false;
      console.log(res);
      this.datas = res.body.object;
      this.totalRec = res.body.object.length;
      console.log(this.totalRec);
      // if(this.totalRec !== 0) {
      //   this.showline = true;
      // } else if(this.totalRec === 0) {
      //   this.showline = false;
      // }

    }, (error) => {
      console.log(error);
      this._progress = false;
    }
    );
  }
}
