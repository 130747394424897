
<form action="" method="post">
    <div class="input-group input-group-merge">
        <input class="form-control" type="text" placeholder="Chercher" style="width: 85%;">
        <div class="input-group-append" data-password="false">
            <div class="input-group-text">
                <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
            </div>
        </div>
    </div>
</form>