import { Component, OnInit, AfterViewInit } from '@angular/core';


declare let general: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'neodesign';


  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    general();
  }
}
