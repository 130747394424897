import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';

@Component({
  selector: 'app-active-desac-user',
  templateUrl: './active-desac-user.component.html',
  styleUrls: ['./active-desac-user.component.css']
})
export class ActiveDesacUserComponent implements OnInit {
variable; labelActive; btnActive; progress;
  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActiveDesacUserComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.data);
    console.log(this.data.data.status);
    if (this.data.data.status === 'ACTIVE') {
      this.labelActive = 'Voulez vous désactiver ce utilisateur ? ';
      this.btnActive = 'Désactiver';
    } else if (this.data.data.status !== 'ACTIVE') {
      this.labelActive = 'Voulez vous activer ce utilisateur ? ';
      this.btnActive = 'Activer';
    }
  }

  // tslint:disable-next-line:typedef
  activeOrdesactive() {

    this.progress = true;
    // tslint:disable-next-line:max-line-length
    (this.data.data.status !== 'ACTIVE' ? this.api.acitiveUser(this.data.data.username) : this.api.disabledUser(this.data.data.username)).subscribe(

      (resp: any) => {
        console.log(resp);
        if (this.data.data.status === 'ACTIVE') {
          this.toast.success('Utilisateur désactivé avec succès');
        } else if (this.data.data.status !== 'ACTIVE') {
          this.toast.success('Utilisateur activé avec succès');
        }
        this.dialogRef.close();
      }, (error) => {
        console.log(error);
        this.toast.success(error.error.message);
      }
    );
  }
}
