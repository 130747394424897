<mat-progress-bar *ngIf="lineBar" mode="indeterminate"></mat-progress-bar>
<h4 style="text-align: center;">AJOUT D'OBSERVATION</h4>
<form #observationForm="ngForm" name="observationForm" (ngSubmit)="save()">
    <!-- <div class="lds-facebook" *ngIf="lineBar">
        <div></div>
        <div></div>
        <div></div>
    </div> -->

    <div class="form-group" style="width: 100%">
        <!-- <label for="example-textarea">Observation</label> -->
        <textarea class="form-control" [(ngModel)]="note" name="note" rows="6" placeholder="entrez votre observation"></textarea>
        <small class="text-danger">{{msg}}</small>
    </div>



    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
      Annuler
    </button> &nbsp;
        <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="submit">
      <span>
        <i class="mdi mdi-database-edit"></i>
        Valider
      </span>
    </button>
    </div>

</form>