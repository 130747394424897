import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-pwd-form',
  templateUrl: './pwd-form.component.html',
  styleUrls: ['./pwd-form.component.css']
})
export class PwdFormComponent implements OnInit {
  hide = true; hiden = true; hidenn = true; progress = false;

  model = new User();

  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PwdFormComponent>
  ) { }

  ngOnInit(): void {
  }

  updateMyPwd() {
    this.progress = true;
    if( !this.model.oldPassword) {
      this.toast.info('Renseignez l\'ancien mot de passe');
    } else if (this.model.newPassword !== this.model.confirmPassword) {
      this.toast.info('Les mots de passe ne sont pas identiques');
    } else {
      // tslint:disable-next-line:max-line-length
      this.api.currentUserpwd(this.model).subscribe(
        (resp: any) => {
          this.progress = false;
          console.log(resp);
          this.toast.success('Mot de passe modifié avec succès');
          this.dialogRef.close();
        }, (error) => {
          console.log(error);
          console.log(error.error.object);
          this.toast.info('Il doi contenir au moin 8 caractères une lettre majuscule, des chiffres et des caractères spéciaux', 'Le mot de passe n\'est pas sécurisé',);
        }
      );
    }
  }

}
