<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/procedure-d-enregistrement-collectif/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/procedure-d-enregistrement-collectif/parameter']">Publicité</a></li>
                                <li class="breadcrumb-item active">Données de comparaison</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">
                <div class="lds-facebook" *ngIf="progress">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div class="col-lg-12">
                    <div class="card-box">
                        &nbsp;&nbsp;&nbsp;
                        <div class="dropdown float-right" style="font-weight: bold;">
                          <button *ngxPermissionsOnly="[ 'EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']"
                            type="button" class="btn btn-light waves-effect waves-light" (click)="file()">
                            <i class="mdi mdi-file-pdf-box"></i>
                              Rapport De Validation
                           </button>&nbsp;&nbsp;&nbsp;
                           <button *ngxPermissionsOnly="[ 'EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']"
                             type="button" class="btn btn-warning waves-effect waves-light">
                           <a href="{{url3 + code}}"style="color: white">
                             <i class="mdi mdi mdi-file-pdf-box"></i>
                             Procès Verbal
                           </a>
                         </button>&nbsp;&nbsp;&nbsp;
                          <button  *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']"
                           class="btn btn-success waves-effect waves-light">
                            <a href="{{fileUrl}}"  target="_blank" style="color:white" >
                              <i class="mdi mdi-microsoft-excel"></i>
                              Tableau de modification
                            </a>
                          </button>
                        </div>
                        <div class="dropdown float-right" style="margin-left: 10px; font-weight: bold;">
                            <button type="button" class="btn btn-danger waves-effect waves-light">
                            <a [routerLink]="['/procedure-d-enregistrement-collectif/enregistrement']" style="color: white">
                              <i class="mdi mdi-chevron-double-left"></i>
                              Retour
                            </a>
                          </button>&nbsp;&nbsp;&nbsp;
                          <button  *ngxPermissionsOnly="['EFBP_PRD_PRESTATAIRE', 'EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']"
                           class="btn btn-primary waves-effect waves-light" (click)="rapport_de_visualisation()">
                           <i class="mdi mdi-microsoft-excel"></i>
                           Exporter le tableau
                           </button>
                          &nbsp;&nbsp;&nbsp;
                        </div>


                        <h4 class="header-title">TABLEAU DE COMPARAISON</h4>
                        <p class="sub-header" style="font-size: 18px">
                            <span style="font-weight: bold;">Code projet: <span style="color: rgb(0, 119, 255);" >{{code}}</span> </span>
                        </p>

                        <ul class="nav nav-tabs nav-bordered nav-justified">
                            <li class="nav-item">
                                <a href="#modif" data-toggle="tab" aria-expanded="true" class="nav-link active">
                                     Modification
                                     <span class="badge badge-primary rounded-circle noti-icon-badge">{{totalRecM}}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#ajout" data-toggle="tab" aria-expanded="false" class="nav-link">
                                     Ajout
                                     <span class="badge badge-primary rounded-circle noti-icon-badge">{{totalRecA}}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#suppression" data-toggle="tab" aria-expanded="false" class="nav-link">
                                    Suppression
                                    <span class="badge badge-primary rounded-circle noti-icon-badge">{{totalRecS}}</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content"><br />


                            <div class="dropdown float-right">

                                <button *ngIf="showBtn" style=" margin-top: 29px; margin-left: 350px; font-weight: bold; "  type="button" class="btn btn-success waves-effect btn-md waves-light " (click)="check({data: data})">
                                    <i class="mdi mdi-check-bold"></i>
                                    Valider
                                </button> <br/><br/>
                                <div class="row">
                                    <div class="col-md-6" >
                                        <div class="form-group">
                                            <label for="example-select">Numéro parcelle</label>
                                            <input type="text" class="form-control" id="field-1" placeholder="Saisir le numéro de parcelle" name="numero" [(ngModel)]="numero" required (keyup)="SearchParcelByNUMO()" >
                                        </div>
                                    </div>
                                    <div class="col-md-6" >
                                        <div class="form-group">
                                            <label for="example-select">Critères de recherche</label>
                                            <select class="form-control" id="example-select" [(ngModel)]="attribut" name="attribut" id="attribut" (change)="retrieveAlldatas()" style="font-size: 15px;">
                                            <option value="TOUT">Tout</option>
                                            <option *ngFor="let at of ATTLIST" value="{{ at }}">{{ at | uppercase }}</option>
                                          </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane active" id="modif">
                                <div class="table-responsive" id="skrool">
                                    <table class="table table-bordered table-centered m-0">

                                        <thead class="thead-light">
                                            <tr>
                                                <th>Parcelle</th>
                                                <th >Attributs</th>
                                                <th >Avant publicité</th>
                                                <th >Après publicité</th>
                                                <th >Actions</th>
                                                <th style="text-align: center;" >
                                                    <div class="form-check" >
                                                      <input class="form-check-input" type="checkbox" [(ngModel)]="checkedAll" name="checkedAll" (change)="selectAllModif()"  id="flexCheckDefault" >
                                                    </div>
                                                    <!-- <span *ngIf="!showCheck" style="font-weight: bold; color: green">Parcelle Validée</span> -->
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="showMessage">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="alert alert-danger" style="text-align: center;">
                                                        <p>Numéro de parcelle inexistant</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="showMessageBody">
                                            <tr *ngFor="let data of modified | keyvalue | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRecM }" >
                                                <!-- <tr *ngFor="let data of modified | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRecM }"> -->
                                                <td>
                                                    <h5 class="m-0 font-weight-normal">{{data.key}}</h5>
                                                </td>
                                                <td colspan="5" style="padding: 0 !important;"  >
                                                    <tbody  style="width: 100%;" >
                                                        <tr *ngFor="let d of data.value; let i = index" >
                                                            <!-- <td style="width: 325px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.parcelNumber }}</h5>
                                                            </td> -->
                                                            <td style="width: 269px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.attribut }}</h5>
                                                            </td>
                                                            <td style="width: 269px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_befor_inspection }}</h5>
                                                            </td>
                                                            <td style="width: 269px;"  >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_after_inspection }}</h5>
                                                            </td>
                                                            <td style="text-align: center; width: 269px; margin: auto;">
                                                                <a class="btn btn-xs btn-danger" (click)="reject({data: d, option: 'REJECT'})"><i class="mdi mdi-close-thick"></i></a> &nbsp;
                                                                <a class="btn btn-xs btn-success" (click)="approval(d.idValidationData)"><i class="mdi mdi-check-bold"></i></a>
                                                            </td>
                                                            <td  style="text-align: center; width: 269px; margin: auto;">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value="d.idValidationData" [(ngModel)]="data.value[i].checked"  (change)="selectSingle(d.idValidationData)" >
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table><br /><br />
                                    <div *ngIf="showMessageBody" style="text-align: center;">
                                        <pagination-controls id="listing_paginated" (pageChange)="p = $event" maxSize="10" directionLinks="true" autoHide="false" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                                    </div>

                                </div>
                            </div>

                            <div class="tab-pane" id="ajout">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-centered m-0">

                                        <thead class="thead-light">
                                            <tr>
                                                <th>Parcelle</th>
                                                <th >Attributs</th>
                                                <th >Avant publicité</th>
                                                <th >Après publicité</th>
                                                <th >Actions</th>
                                                <th style="text-align: center;" >
                                                    <div class="form-check">
                                                      <input class="form-check-input" type="checkbox" [(ngModel)]="checkedAllAd" name="checkedAllAd" (change)="selectAllAjouter()"  id="flexCheckDefault" >
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="showMessage2">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="alert alert-danger" style="text-align: center;">
                                                        <p>Numéro de parcelle inexistant</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="showMessageBody2">
                                            <tr *ngFor="let data of added | keyvalue | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: q, totalItems: totalRecA }"  >
                                                <!-- <tr *ngFor="let data of modified | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRecM }"> -->
                                                <td>
                                                    <h5 class="m-0 font-weight-normal">{{data.key}}</h5>
                                                </td>
                                                <td colspan="5" style="padding: 0 !important;">
                                                    <tbody  style="width: 100%;" >
                                                        <tr *ngFor="let d of data.value; let i = index" >
                                                            <!-- <td style="width: 325px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.parcelNumber }}</h5>
                                                            </td> -->
                                                            <td style="width: 269px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.attribut }}</h5>
                                                            </td>
                                                            <td style="width: 269px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_befor_inspection }}</h5>
                                                            </td>
                                                            <td style="width: 269px;"  >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_after_inspection }}</h5>
                                                            </td>
                                                            <td style="text-align: center; width: 269px;">
                                                                <a class="btn btn-xs btn-danger" (click)="reject({data: d, option: 'REJECT'})"><i class="mdi mdi-close-thick"></i></a> &nbsp;
                                                                <a class="btn btn-xs btn-success" (click)="approval(d.idValidationData)"><i class="mdi mdi-check-bold"></i></a>
                                                            </td>
                                                            <td  style="text-align: center; width: 269px; margin: auto;">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value="d.idValidationData" [(ngModel)]="data.value[i].checked"  (change)="selectSingle(d.idValidationData)" >
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table><br /><br />
                                    <div *ngIf="showMessageBody2" style="text-align: center;">
                                        <pagination-controls id="listing_paginated" (pageChange)="q = $event" maxSize="10" directionLinks="true" autoHide="false" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                                    </div>

                                </div>
                                <!-- end .table-responsive-->
                            </div>
                            <div class="tab-pane" id="suppression">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-centered m-0">

                                        <thead class="thead-light">
                                            <tr>
                                                <th>Parcelle</th>
                                                <th >Attributs</th>
                                                <th >Avant publicité</th>
                                                <th >Après publicité</th>
                                                <th >Actions</th>
                                                <th style="text-align: center;" >
                                                    <div class="form-check">
                                                      <input class="form-check-input" type="checkbox" [(ngModel)]="checkedAllDel" name="checkedAllDel" (change)="selectAllSupprimee()"  id="flexCheckDefault" >
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="showMessage3">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="alert alert-danger" style="text-align: center;">
                                                        <p>Numéro de parcelle inexistant</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="showMessageBody3">
                                            <tr *ngFor="let data of deleted | keyvalue | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: k, totalItems: totalRecS }"  >
                                                <!-- <tr *ngFor="let data of modified | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRecM }"> -->
                                                <td>
                                                    <h5 class="m-0 font-weight-normal">{{data.key}}</h5>
                                                </td>
                                                <td colspan="5" style="padding: 0 !important;">
                                                    <tbody  style="width: 100%;" >
                                                        <tr *ngFor="let d of data.value; let i = index" >
                                                            <!-- <td style="width: 325px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.parcelNumber }}</h5>
                                                            </td> -->
                                                            <td style="width: 270px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.attribut }}</h5>
                                                            </td>
                                                            <td style="width: 270px;" >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_befor_inspection }}</h5>
                                                            </td>
                                                            <td style="width: 272px;"  >
                                                                <h5 class="m-0 font-weight-normal">{{d.value_after_inspection }}</h5>
                                                            </td>
                                                            <td style="text-align: center; width: 272px;">
                                                                <a class="btn btn-xs btn-danger" (click)="reject({data: d, option: 'REJECT'})"><i class="mdi mdi-close-thick"></i></a> &nbsp;
                                                                <a class="btn btn-xs btn-success" (click)="approval(d.idValidationData)"><i class="mdi mdi-check-bold"></i></a>
                                                            </td>
                                                            <td  style="text-align: center; width: 272px; margin: auto;">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value="d.idValidationData" [(ngModel)]="data.value[i].checked"  (change)="selectSingle(d.idValidationData)" >
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table><br /><br />
                                    <div *ngIf="showMessageBody3" style="text-align: center;">
                                        <pagination-controls id="listing_paginated" (pageChange)="k = $event" maxSize="10" directionLinks="true" autoHide="false" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                                    </div>
                                </div>
                            </div>
                            <button style="float:right; bottom: 75px; font-weight: bold;"
                                *ngxPermissionsOnly="[ 'EFBP_PRD_ASS_PROJ','EFBP_PRD_ADM_DEVOPS', 'EFBP_PRD_DIR_CADASTRAL','EFBP_PRD_ADM_FUNCTIONAL']"
                                type="button" class="btn btn-success waves-effect btn-md waves-light" (click)="all()">
                                <i class="mdi mdi-check-bold"></i>
                                Approbation des modifications
                            </button>
                        </div>



                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->
