import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { User } from 'src/app/shared/models/user';
import Swal from 'sweetalert2';
import { Model } from '../../shared/models/model';

declare let $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  user = new User();
  version;
  _progress = false;
  constructor(
    private api: ApiProviders,
    private toast: ToastrService,
    private router: Router,
  ) { }


  ngOnInit(): void {
    // localStorage.setItem('PORT', '9090');
  }

  changeVersion(port): void {
    console.log(port);
    this.api.PORT = port;
    localStorage.setItem('PORT', port);
  }

  save() {
      console.log(this.user.username, this.user.password)
      this._progress = true;
      this.api.login(this.user).subscribe(
        (data: any) => {
          this._progress = true;
          console.log(data);
          console.log(data.token);
          this.router.navigate(['/procedure-d-enregistrement-collectif/statistique']);
          window.localStorage.setItem('data', JSON.stringify(data));
          window.localStorage.setItem('token', data.token);
          window.localStorage.setItem('username', this.user.username);
          this.user.username = '';
          this.user.password = '';
        },
        (error) => {
          console.log(error);
          console.log(error.error.message);
          this._progress = false;
          if (error.error.message === 'Bad credentials') {
            this.toast.info('Mot de passe incorrect');
          } else if (error.error.error === 'Unauthorized') {
            this.toast.info('Identifiant mail incorrect');
          } else if (error.error.message === 'Username or Password should not be empty') {
            this.toast.info('Le nom d\'utilisateur ou le mot de passe ne doit pas être vide');
          } else if (error.error.message === 'User is disabled') {
            this.toast.error('Utilisateur désactivé', 'Vueiller contacter l\'administrateur système');
          }
          // this.toast.warning('réessayer' , 'Utilisateur inexistant');Unauthorized
        }
      );

  }

  getport() {
    const port = localStorage.getItem('PORT');
    return port;
  }

}
