import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAnyRessourcesDirective } from './apiProviders/ressources.directives';

@NgModule({
  declarations: [HasAnyRessourcesDirective],
  imports: [ CommonModule ],
  exports: [],
  providers: [],
})
export class SharedModule {}
