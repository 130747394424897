<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <!--  -->
                        </div>
                        <h4 class="page-title">Statistiques</h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->

            <div class="row">
                <div class="col-md-6">
                    <div class="card-box">
                        <!-- <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i> -->
                        <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1 float-right" role="status" aria-hidden="true"></span>
                        <h4 class="mt-0 font-16">Nombre de projets</h4>
                        <h2 class="text-primary my-3 text-center"><span> {{totalProjet}}</span></h2>
                        <p class="text-muted mb-0">Total ouvert: {{openProject}} <span class="float-right"> Total fermés : {{closedProject}} </span></p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card-box">
                        <!-- <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i> -->
                        <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1 float-right" role="status" aria-hidden="true"></span>
                        <h4 class="mt-0 font-16">Nombre de parcelles enregistrées</h4>
                        <h2 class="text-primary my-3 text-center"><span> {{totalParcels}} </span></h2>
                        <p class="text-muted mb-0">Convenus: {{parcelConvenu}} <span class="float-right">Conflictuel: {{parcelConflictuel}} </span></p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card-box">
                        <!-- <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i> -->
                        <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1 float-right" role="status" aria-hidden="true"></span>
                        <h4 class="mt-0 font-16">Nombre de téléchargements</h4>
                        <h2 class="text-primary my-3 text-center"><span> {{totalUpload}} </span></h2>
                        <p class="text-muted mb-0">Corrects: {{uploadCorrect}} <span class="float-right">Erronés: {{uploadIncorrect}} </span></p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card-box">
                        <!-- <i class="fa fa-info-circle text-muted float-right" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="More Info"></i> -->
                        <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1 float-right" role="status" aria-hidden="true"></span>
                        <h4 class="mt-0 font-16">Nombre de publicités</h4>
                        <h2 class="text-primary my-3 text-center"><span> {{totalInspection}} </span></h2>
                        <p class="text-muted mb-0">Ouvertes: {{inspectionOpen}} <span class="float-right">Fermées: {{inspectionClosed}} </span></p>
                    </div>
                </div>
            </div>
            <!-- end row -->

        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->
