import { DatePipe, formatDate } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { Download } from 'src/app/shared/models/upload';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-upload-file',
  templateUrl: './add-edit-upload-file.component.html',
  styleUrls: ['./add-edit-upload-file.component.css', './loader-waiting.css', './add-edit-custom-style.scss'],
})
export class AddEditUploadFileComponent implements OnInit, AfterViewInit {
  model = new Download();
  lineBar = false; lineBar1 = false; attente = false; loader = false; press; i; inprogess; texte;

  @ViewChild('file') file: ElementRef;
  json_file_name = '';  pv_file_name = '';  excel_file_name = ''; excel_social_file = ''; attachment_file = '';

  j = 0;
  username: string; insaeCode: string;

  verify1 = false; verify2 = false; verify3 = false; verify4 = false; verify5 = false; verify6 = false;
  verify7 = false; verify8 = false; verify9 = false; verify0 = false; 

  show = false;

  hideOtherdataForUser = true;

  fileToUpload: string;

  comm: any; arrond: string; action: string; statut: string; 
  
  beginAt: any  = new Date(); beginAt1: any;
  endAt: any = new Date(); endAt1: any;

  projectCode; publicationLocation; oldFileName; 

  choice; choice1; choice2; choice3; 

  message: any; dpmt: any; comu: any; arrd: any; fileUpload;

  requiredInput = false; optionalInput1 = false; optionalInput2 = false; @Input() progress: number;

  @Input() total: number;
  showline = true;
   chosenGeojson: FileList; chosenPvFile: FileList; chosenExcelFile: FileList; chosenExceldataFile: FileList; chosenZypfil: FileList;
   existingFile: File;
  
   progresss = 0;

  msg = '';  filename2;  filename3;

  voirBtn = true;
  block = false; OptionMod =  true; libTexte = 'Fichier GeoJSON tout en un';
  @ViewChild('myBar', { static: false }) myBar: ElementRef;
  constructor(
    private api: ApiProviders,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditUploadFileComponent>
  ) {}

  ngOnInit(): void {
    this.libTexte = 'Fichier GeoJSON des données topographiques'
    console.log(this.data);
    console.log(this.data.option);
    if (this.data.option === 'UPLOAD') {
      this.texte = 'Valider';
      this.voirBtn = true;
      this.username = this.User();
      this.choice = 'choiceOne';
    } else if (this.data.option === 'UPDATE') {
      this.texte = 'Modifier';
      this.hideOtherdataForUser = false;
      this.voirBtn = false;
      this.choice = 'choiceTwo';
      this.model.action = 'VALIDATION_AND_PUBLICATION';
      this.optionalInput1 = true;
      this.model.projectCode = this.data.data.projectCode;
      this.model.username = this.User();
      this.model.publicationLocation = this.data.data.placeOfPublication;

      this.beginAt =this.datePipe.transform(this.data.data.beginAt, 'dd-MM-yyyy');
      this.endAt =this.datePipe.transform(this.data.data.endAt, 'dd-MM-yyyy');

      console.log(this.beginAt , this.endAt)
 

   
    }

  }

  Mod() {
    console.log(this.block)
    if (this.block === true) {
      this.libTexte = 'Fichier GeoJSON tout en un'
      this.OptionMod = false;
    } else if (this.block === false) {
      this.libTexte = 'Fichier GeoJSON des données topographiques'
      this.OptionMod = true
    }
  }

  // tslint:disable-next-line: typedef
  lechoix() {
    console.log(this.choice);

    if (this.choice === 'choiceOne') {
      this.optionalInput1 = false;
      this.optionalInput2 = false;
      // this.optionalInput2  = false;
    } else if (this.choice === 'choiceTwo') {
      this.optionalInput1 = true;
      this.optionalInput2 = false;
      // this.optionalInput2  = false;
    } else if (this.choice === 'choiceThree') {
      this.optionalInput1 = false;
      this.optionalInput2 = true;
    } else if (this.choice === 'choiceFour') {
      this.optionalInput1 = false;
      this.optionalInput2 = false;
    }
  }

  chooseGeojsonfile(event): void {
    this.lineBar1 = true;
    this.chosenGeojson = event.target.files;
    console.log(this.chosenGeojson);
    console.log(this.chosenGeojson[0]);
    console.log(this.chosenGeojson[0].name);
    this.json_file_name = this.chosenGeojson[0].name;
  }

  choosePVfile(event): void {
    console.log('PV');
    // this.lineBar1 = true;
    this.chosenPvFile = event.target.files;
    console.log(this.chosenPvFile);
    console.log(this.chosenPvFile[0]);
    console.log(this.chosenPvFile[0].name);
    this.pv_file_name = this.chosenPvFile[0].name;
  }

  chooseExcelFile(event): void {
    console.log('EXCEL');
    // this.lineBar1 = true;
    this.chosenExcelFile = event.target.files;
    console.log(this.chosenExcelFile);
    console.log(this.chosenExcelFile[0]);
    console.log(this.chosenExcelFile[0].name);
    this.excel_file_name = this.chosenExcelFile[0].name;
  }

  chooseExcelSocial(event): void {
    console.log('EXCEL');
    // this.lineBar1 = true;
    this.chosenExceldataFile = event.target.files;
    console.log(this.chosenExceldataFile);
    console.log(this.chosenExceldataFile[0]);
    console.log(this.chosenExceldataFile[0].name);
    this.excel_social_file = this.chosenExceldataFile[0].name;
  }

  chooseAttachFile(event): void {
    console.log('EXCEL');
    // this.lineBar1 = true;
    this.chosenZypfil = event.target.files;
    console.log(this.chosenZypfil);
    console.log(this.chosenZypfil[0]);
    console.log(this.chosenZypfil[0].name);
    this.attachment_file = this.chosenZypfil[0].name;
  }

  validation() {
    if (this.block === true) {
      this.validerFormV1();
    } else {
      this.validerFormV2();
    }
  }

  validerFormV1() {
    this.progress = 1;

    if (this.choice === 'choiceOne') {
      this.model.action = 'ONLY_VALIDATION';
    } else if (this.choice === 'choiceTwo') {
      this.model.action = 'VALIDATION_AND_PUBLICATION';
    } else if (this.choice === 'choiceThree') {
      this.model.action = 'APPROVAL_AFTER_INSPECTION';
    }else if (this.choice === 'choiceFour') {
      this.model.action = 'ONLY_VALIDATION_AFTER_INSPECTION';
    }

    if (!this.model.projectCode) {
      this.verify1 = true;
    } else if (!this.model.publicationLocation && this.choice === 'choiceTwo') {
      this.verify2 = true;
    } else if (
      this.convert(this.beginAt) > this.convert(this.endAt) &&
      this.choice === 'choiceTwo'
    ) {
      this.verify5 = true;
    } else if (
      this.convert(this.beginAt) === this.convert(this.endAt) &&
      this.choice === 'choiceTwo'
    ) {
      this.verify6 = true;
    } else if (!this.json_file_name) {
      this.verify3 = true;
    } else if (this.json_file_name && !this.model.projectCode) {
      this.verify4 = true;
    } else if (this.choice === 'choiceThree' && !this.pv_file_name) {
      this.verify7 = true;
    } else if (this.choice === 'choiceThree' && !this.excel_file_name) {
      this.verify8 = true;
    } else if (this.choice === 'choiceOne' && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadFile(
        this.model.geojson,  this.model.proces_verbal, this.model.repertoire_excel,  this.model.username, this.model.beginAt, 
        this.model.endAt, this.model.projectCode, this.model.publicationLocation, this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    } else if (this.choice === 'choiceTwo' && this.data.option === 'UPLOAD'){
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadFile(
        this.model.geojson,  this.model.proces_verbal, this.model.repertoire_excel,  this.model.username, this.model.beginAt, 
        this.model.endAt, this.model.projectCode, this.model.publicationLocation, this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    } else if ((this.choice === ('choiceThree' || 'choiceFour')) && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.proces_verbal = this.chosenPvFile[0];
      this.model.repertoire_excel = this.chosenExcelFile[0];
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadFile(
        this.model.geojson,  this.model.proces_verbal, this.model.repertoire_excel,  this.model.username, this.model.beginAt, 
        this.model.endAt, this.model.projectCode, this.model.publicationLocation, this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    } else if ( this.choice === 'choiceFour' && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadFile(
        this.model.geojson,  this.model.proces_verbal, this.model.repertoire_excel,  this.model.username, this.model.beginAt, 
        this.model.endAt, this.model.projectCode, this.model.publicationLocation, this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    }
  }


  validerFormV2() {
    this.progress = 1;

    if (this.choice === 'choiceOne') {
      this.model.action = 'ONLY_VALIDATION';
    } else if (this.choice === 'choiceTwo') {
      this.model.action = 'VALIDATION_AND_PUBLICATION';
    } else if (this.choice === 'choiceThree') {
      this.model.action = 'APPROVAL_AFTER_INSPECTION';
    }else if (this.choice === 'choiceFour') {
      this.model.action = 'ONLY_VALIDATION_AFTER_INSPECTION';
    }

    if (!this.model.projectCode) {
      this.verify1 = true;
    } else if (!this.model.publicationLocation && this.choice === 'choiceTwo') {
      this.verify2 = true;
    } else if (
      this.convert(this.beginAt) > this.convert(this.endAt) &&
      this.choice === 'choiceTwo'
    ) {
      this.verify5 = true;
    } else if (
      this.convert(this.beginAt) === this.convert(this.endAt) &&
      this.choice === 'choiceTwo'
    ) {
      this.verify6 = true;
    } else if (!this.json_file_name) {
      this.verify3 = true;
    } else if (this.json_file_name && !this.model.projectCode) {
      this.verify4 = true;
    } else if (this.choice === 'choiceThree' && !this.pv_file_name) {
      this.verify7 = true;
    } else if (this.choice === 'choiceThree' && !this.excel_file_name) {
      this.verify8 = true;
    } else if (this.block = true && !this.excel_social_file) {
      this.verify9 = true;
    } else if (this.block = true && !this.attachment_file) {
      this.verify0 = true;
    } else if (this.choice === 'choiceOne' && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.excel = this.chosenExceldataFile[0];
      this.model.attachment = this.chosenZypfil[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadV2File(
        this.model.geojson, this.model.excel,  this.model.attachment,   this.model.proces_verbal, this.model.repertoire_excel, this.model.username, 
        this.model.beginAt, this.model.endAt, this.model.projectCode, this.model.publicationLocation,  this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    } else if (this.choice === 'choiceTwo' && this.data.option === 'UPLOAD'){
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.excel = this.chosenExceldataFile[0];
      this.model.attachment = this.chosenZypfil[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadV2File(
        this.model.geojson, this.model.excel,  this.model.attachment,   this.model.proces_verbal, this.model.repertoire_excel, this.model.username, 
        this.model.beginAt, this.model.endAt, this.model.projectCode, this.model.publicationLocation,  this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    } else if (this.choice === 'choiceThree' && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.excel = this.chosenExceldataFile[0];
      this.model.attachment = this.chosenZypfil[0];
      this.model.proces_verbal = this.chosenPvFile[0];
      this.model.repertoire_excel = this.chosenExcelFile[0];
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadV2File(
        this.model.geojson, this.model.excel,  this.model.attachment,   this.model.proces_verbal, this.model.repertoire_excel, this.model.username, 
        this.model.beginAt, this.model.endAt, this.model.projectCode, this.model.publicationLocation,  this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
                title: 'Un mail vous sera envoyé'
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    }else if (this.choice === 'choiceFour' && this.data.option === 'UPLOAD') {
      this.attente = true;
      this.texte = 'Patientez SVP';
      this.model.username = this.User();
      this.model.beginAt = this.convert(this.beginAt);
      this.model.endAt = this.convert(this.endAt);
      // this.existingFile = this.chosenGeojson.item(0);
      this.model.geojson = this.chosenGeojson[0];
      this.model.excel = this.chosenExceldataFile[0];
      this.model.attachment = this.chosenZypfil[0];
      this.model.proces_verbal = null;
      this.model.repertoire_excel = null;
      console.log(this.model);
      // tslint:disable-next-line:max-line-length
      this.api.uploadV2File(
        this.model.geojson, this.model.excel,  this.model.attachment,   this.model.proces_verbal, this.model.repertoire_excel, this.model.username, 
        this.model.beginAt, this.model.endAt, this.model.projectCode, this.model.publicationLocation,  this.model.action, this.model.oldFileName
        ).subscribe((event) => {
            console.log(event);
            console.log(HttpEventType.UploadProgress);
            // let m = data.message;
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
              console.log(this.progress);
            } else if (event instanceof HttpResponse) {
              this.dialogRef.close();
              this.msg = event.body.message;
              Swal.fire({
                type: 'success',
                text: this.msg,
                title: 'Un mail vous sera envoyé'
              });
              this.lineBar = false;
              this.attente = false;
              // this.FileDetail = this.uploadService.getFiles();
            }
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
          },
          (error) => {
            console.log(error);
            console.log(error.message);
            console.log(error.error.message);
            this.attente = false;
            this.lineBar = false;
            this.dialogRef.close();
            this.model.projectCode = '';
            this.model.oldFileName = '';
            this.model.publicationLocation = '';
            this.json_file_name = '';
            this.existingFile = null;
            Swal.fire({
              type: 'error',
              text: error.error.message,
            });

            this.progress = 0;
            this.msg = 'Error occured while uploading file';
            this.existingFile = undefined;
          }
        );
      this.chosenGeojson = undefined;
    }
  }

  User() {
    const User = window.localStorage.getItem('username');
    return User;
  }

  convert(z) {
    const now = new Date(z);
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();

    const finalconv =  '' + y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
    return finalconv;
  }

  reconvert(val) {
    const d = new Date(val);
    const ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('fr', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);
    console.log(`${da}/${mo}/${ye}`);
    const res = '' + da + '/' + mo + '/' + ye;
    return res;
  }

  ngAfterViewInit(): void {}

  test(): void {
    if (this.i === 0) {
      this.i = 1;
      const elem = document.getElementById('myBar');
      let width = 1;
      const id = setInterval(frame, 10);
      // tslint:disable-next-line:typedef
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          this.i = 0;
        } else {
          width++;
          elem.style.width = width + '%';
          elem.innerHTML = width + '%';
          this.inprogess = elem.innerHTML;
        }
      }
    }
  }

  update(): void {
    this.attente = true;
    console.log(this.model);
    this.model.beginAt = this.convert(this.beginAt);
    this.model.endAt = this.convert(this.endAt);

    this.api.updateDataafterInspection(this.model).subscribe(
      (resp: any) => {
        this.attente = false;
        console.log(resp);
        Swal.fire({
          type: 'success',
          text: resp.message,
        });
        this.dialogRef.close();
      },
      (error) => {
        console.log(error);
        Swal.fire({
          type: 'error',
          text: error.error.message,
        });
      }
    );
  }
}
