import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { Observations } from 'src/app/shared/models/observation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-approval-data',
  templateUrl: './approval-data.component.html',
  styleUrls: ['./approval-data.component.css'],
})
export class ApprovalDataComponent implements OnInit {
  choix: string;
  optionalInput1 = false;
  note;
  progress = false;
  lineBar = false;
  msg = '';
  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApprovalDataComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.data);
    console.log(this.data.data.data.idValidationData);
  }

  // tslint:disable-next-line:typedef
  // tslint:disable-next-line:typedef
  save() {
    console.log(this.data.data.id, this.note);
    if (!this.note) {
      this.msg = 'Ce champs est obligatoire';
    } else {
      Swal.fire({
        title: 'Etes vous sûr ?',
        text: 'de vouloir effectuer cette validation..!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1DD0D9',
        cancelButtonColor: '#F7A47A',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      }).then((result) => {
        if (result.value) {
          this.api.rejectDatas(this.data.data.data.idValidationData, this.note).subscribe(
            (res: any) => {
              console.log(res);
              if (res.message === 'Opération effectuée avec succès') {
                Swal.fire({
                  type: 'success',
                  text: res.message,
                });
              }
              this.dialogRef.close();
            },
            (error) => {
              console.log(error);
              this.progress = true;
            }
          );
        }
      });
    }
  }
}
