<h4 style="text-align: center;">
    FORMULAIRE DE VALIDATION DU FICHIER GeoJSON</h4>
<form #observationForm="ngForm" name="observationForm" (ngSubmit)="save()">
    <!-- <div class="lds-facebook" *ngIf="lineBar">
        <div></div>
        <div></div>
        <div></div>
    </div> -->
    <div class="row">
        <div class="col-md-12" style="color: black;">
            <div class="form-group">
                <!-- <label for="choice" class="control-label">Type Validation&nbsp; <span class="text-danger">*</span> </label> -->
                <div class="form-group" style="width: 100%; text-align: center;">
                    <mat-radio-group style="justify-content:space-between" aria-label="Select an option" name="choice"
                        [(ngModel)]="choix" (change)="thechoice()">
                        <mat-radio-button value="valider" color="primary">VALIDER</mat-radio-button> &nbsp;&nbsp;
                        <mat-radio-button value="rejetter" color="primary">REJETTER</mat-radio-button>&nbsp;&nbsp;
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group" style="width: 100%" *ngIf="optionalInput1">
        <label for="example-textarea">Observation</label>
        <textarea class="form-control" [(ngModel)]="model.note" name="note"
            id="example-textarea" rows="6" placeholder="entrez votre commentaire"></textarea>
        <small class="text-danger">{{msg}}</small>
    </div>


    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
            Annuler
        </button> &nbsp;
        <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light"
            type="submit">
            <span>
                <i class="mdi mdi-database-edit"></i>
                <span *ngIf="lineBar" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                {{validate}}
            </span>
        </button>
    </div>

</form>