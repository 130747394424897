import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';

@Component({
  selector: 'app-affect-profile',
  templateUrl: './affect-profile.component.html',
  styleUrls: ['./affect-profile.component.css'],
})
export class AffectProfileComponent implements OnInit {
  donnee: any[] = []; profile;
  progress = false;

  formTitle = 'AFFECTATION DE PROFIL ';
  btnAction = 'Affecter';
  constructor(
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AffectProfileComponent>
  ) {}

  ngOnInit(): void {
    this.retrieveAlldatas();
    console.log(this.data);
    this.profile = "selected"
  }

  async retrieveAlldatas() {
    this.progress = true;
    await this.api.getUsersProfile().subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        this.donnee = res.body;
      },
      (error) => {
        console.log(error);
        this.progress = false;
      }
    );
  }

  save() {

    this.progress = true;
    this.api.affect(this.data.data.username, this.profile ).subscribe(

      (resp: any) => {
        console.log(resp);
        this.toast.success('Profile affecté avec succès');
        this.dialogRef.close();
      }, (error) => {
        console.log(error);
        this.toast.success(error.error.message);
      }
    );
  }
}
