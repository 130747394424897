import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import { Approval } from 'src/app/shared/models/correction.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-data-compare',
  templateUrl: './data-compare.component.html',
  styleUrls: ['./data-compare.component.css']
})
export class DataCompareComponent implements OnInit {
choix; model = new Approval(); lineBar = false;
  constructor(
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DataCompareComponent>,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.model.id = this.data.data.data.id;
    this.model.inspectionAction = this.data.data.data.inspectionAction;
    this.model.newValue = this.data.data.data.newValue;
    this.model.oldValue = this.data.data.data.oldValue;
    this.model.path = this.data.data.data.path;
    this.model.projectCode = this.data.data.data.projectCode;
    this.model.projectParcelNumber = this.data.data.data.projectParcelNumber;
  }

  action(): void {

  }

  save(): void{
    this.lineBar = false;
    this.api.sendApprovalCorrection(this.model).subscribe(
      (resp: any) => {
        this.lineBar = false;
        console.log(resp);
        this.dialogRef.close();
        Swal.fire({
          type: 'success',
          text: resp.message,
        });
      },
      (error) => {
        console.log(error);
        this.lineBar = false;
        this.dialogRef.close();
        Swal.fire({
          type: 'error',
          text: error.error.message,
        });
      }
    );
  }
}
