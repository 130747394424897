<div class="loading" *ngIf="attente">Loading&#8230;</div>
<h4 style="text-align: center;">FORMULAIRE D'ENVOI DE FICHIER</h4> <br>

<form #uploadForm="ngForm" name="uploadForm" >
    
    <div mat-dialog-content class="buyer">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label for="field-1" class="control-label" style="color: black;"> 
                    Code Projet &nbsp;  <span class="text-danger">*</span> 
                </label>

                   <input type="text" class="form-control" id="field-1" placeholder="code du projet" name="projectCode" [(ngModel)]="model.projectCode" required>
                <small class="text-danger" *ngIf="verify1">Ce champ est obligatoire</small>
            </div>
        </div>
    </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="choice" class="control-label" style="color: black;">
                        Type Validation  &nbsp; <span class="text-danger">*</span>
                     </label>
                    <div class="form-group" style="width: 100%">
                        <mat-radio-group style="justify-content:space-between" aria-label="Select an option" name="choice" [(ngModel)]="choice" (change)="lechoix()">
                            <mat-radio-button *ngIf="hideOtherdataForUser" value="choiceOne" color="primary" [checked]="true" style="color: black;">Seul </mat-radio-button>
                              &nbsp;&nbsp;
                            <mat-radio-button value="choiceTwo" color="primary" style="color: black;">Avec publicité </mat-radio-button>
                              &nbsp;&nbsp;
                              <mat-radio-button *ngIf="hideOtherdataForUser" value="choiceFour" color="primary" style="color: black;"> Comparaison </mat-radio-button>
                              &nbsp;&nbsp;
                            <mat-radio-button *ngIf="hideOtherdataForUser" value="choiceThree" color="primary" style="color: black;"> Après publicité </mat-radio-button>
                            &nbsp;&nbsp;
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="optionalInput1">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="field-4" class="control-label" style="color: black;">Lieu de publication &nbsp;<span
                  class="text-danger">*</span>
              </label>
                        <input type="text" class="form-control" id="field-4" name="publicationLocation" [(ngModel)]="model.publicationLocation" placeholder="Lieu de publication du fichier" required>
                        <small class="text-danger" *ngIf="verify2">Ce champ est obligatoire</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-date1" style="color: black;">Date début &nbsp;<span class="text-danger">*</span></label>
                        <input class="form-control" id="example-date1" type="date" min="2000-01-01" name="beginAt" [(ngModel)]="beginAt" [value]="beginAt" required>
    
                        <small class="text-danger" *ngIf="verify6"> Vérifiez si les champs dates sont bien renseignés</small>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-date2" style="color: black;">Date fin &nbsp;<span class="text-danger">*</span></label>
                        <input class="form-control" id="example-date2" type="date" min="2000-01-01" name="endAt" [(ngModel)]="endAt" [value]="endAt" required>
    
                        <small class="text-danger" *ngIf="verify5">Cette date doit être sup à {{beginAt | date: 'short' }}</small>
                        <small class="text-danger" *ngIf="verify6"> Vérifiez si les champs dates sont bien renseignés</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="trigger">
        
            <div *ngIf="optionalInput2">
        
                <div class="form-group">
                    <div>
                        <label for="input-6" style="color: black;">Procès Verbal &nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input class="form-control" placeholder="nom du fichier" [(ngModel)]="pv_file_name" accept=".pdf" disabled name="pv_file_name" maxlength="200" required>
                            <button type="button" (click)="file1.click()" class="btn btn-outline-warning waves-effect waves-light">
                                <i class="mdi mdi-folder-upload"></i>
                            </button>
                            <input hidden type="file" #file1 (change)="choosePVfile($event)" required />
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="verify7">Ce document est obligatoire</small>
                </div>
        
                <div class="form-group">
                    <div>
                        <label for="input-6" style="color: black;">Tableau de modification &nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input class="form-control" placeholder="nom du fichier" [(ngModel)]="excel_file_name" accept=".csv" disabled name="excel_file_name" maxlength="200" required>
                            <button type="button" (click)="file2.click()" class="btn btn-outline-warning waves-effect waves-light">
                               <i class="mdi mdi-folder-upload"></i>
                            </button>
                            <input hidden type="file" #file2 (change)="chooseExcelFile($event)" required />
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="verify8">Ce document est obligatoire</small>
                </div>
            </div>
            <div>
                <div class="custom-control custom-switch">
                    <input type="checkbox" [(ngModel)]="block" name="block" class="custom-control-input" id="customSwitch1" (change)="Mod()">
                    <label class="custom-control-label" for="customSwitch1" style="color: black;">Fichiers </label>
                </div>
            </div> <br>
        
            <div style="width: 100%;" *ngIf="OptionMod" class="msg">
                <div class="form-group" >
                    <div>
                        <label for="input-6" style="color: black;">Fichier excel des données sociales &nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input class="form-control" placeholder="nom du fichier" [(ngModel)]="excel_social_file" accept=".csv" disabled name="excel_social_file" maxlength="200" required>
                            <button type="button" (click)="file2.click()" class="btn btn-outline-warning waves-effect waves-light">
                                <i class="mdi mdi-folder-upload"></i>
                            </button>
                            <input hidden type="file" #file2 (change)="chooseExcelSocial($event)" required />
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="verify9">Il est important d'uploader ce fichier</small>
                </div>
            
                <div class="form-group">
                    <div>
                        <label for="input-6" style="color: black;">Autres fichiers(.zip) &nbsp;<span class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input class="form-control" placeholder="nom du fichier" [(ngModel)]="attachment_file" accept=".zip" disabled name="attachment_file" maxlength="200" required>
                            <button type="button" (click)="file3.click()" class="btn btn-outline-warning waves-effect waves-light">
                                <i class="mdi mdi-folder-upload"></i>
                            </button>
                            <input hidden type="file" #file3 (change)="chooseAttachFile($event)" required />
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="verify0">Uploader le fichier compressé à envoyer</small>
                </div>
                
            </div>
            <div class="form-group" *ngIf="hideOtherdataForUser">
                <div>
                    <label for="input-6" style="color: black;">{{libTexte}} &nbsp;<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                        <input class="form-control" placeholder="fichier geoJSON" accept="application/JSON" [(ngModel)]="json_file_name" disabled name="json_file_name" maxlength="200" required>
                        <button type="button" (click)="file0.click()" class="btn btn-outline-warning waves-effect waves-light">
                            <i class="mdi mdi-folder-upload"></i>
                        </button>
                        <input hidden type="file" #file0 (change)="chooseGeojsonfile($event)" required />
                    </div>
                </div>
                <small class="text-danger" *ngIf="verify3">Uploader le fichier à envoyer &nbsp;<span class="text-danger">*</span></small>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div id="myProgress" *ngIf="lineBar1">
                        <div id="myBar" class="progress-value" style="text-align: center"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-divider></mat-divider><br>

    <div style="float: right;">
        <button id="canceled" style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
              Annuler
       </button> &nbsp;
        <button id="validated" *ngIf="voirBtn" type="button" class="btn btn-success btn-rounded waves-effect waves-light" (click)="validation()">
           <span class="btn-label"><i class="mdi mdi-check-bold"></i></span>
           <span *ngIf="attente" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> {{texte}}
        </button> &nbsp;

        <button id="uploaded" *ngIf="!voirBtn" type="button" class="btn btn-warning btn-rounded waves-effect waves-light" dir="ltr" (click)="update()">
           <span class="btn-label"><i class="mdi mdi-check-bold"></i></span>
           <span *ngIf="attente" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> {{texte}}
        </button>
    </div>

</form>