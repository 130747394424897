import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from '../../shared/apiProviders/api-providers.service';
import { PwdFormComponent } from './pwd-form/pwd-form.component';

@Component({
  selector: 'app-user-profile-info',
  templateUrl: './user-profile-info.component.html',
  styleUrls: ['./user-profile-info.component.css']
})
export class UserProfileInfoComponent implements OnInit {
  progress = false; datas; name; prenom; email; contact; profile;
  constructor(
       private api: ApiProviders,
       private toast: ToastrService,
       public dialog: MatDialog
       ) { }

  ngOnInit(): void {
    this.usersInfo();
  }

  // tslint:disable-next-line:typedef
  async usersInfo() {
    this.progress = true;
    await this.api.getUserInformation(this.user()).subscribe((res: any) => {
      this.progress = false;
      console.log(res);
      console.log(res.object);
      this.datas = res.object;
      this.name = this.datas.fullName;
      this.prenom = this.datas.lastName;
      this.email = this.datas.mail;
      this.contact = this.datas.phone;
      this.profile = this.datas.profil;

    }, (error) => {
      console.log(error);
      this.progress = false;
    }
    );
  }

  // tslint:disable-next-line:typedef
  user() {
    const User = window.localStorage.getItem('username');
    return User;
  }

  open(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(PwdFormComponent, { disableClose: true, width: '700px', data: data });
    dialogRef.afterClosed().subscribe(result => {
      this.usersInfo();
    });
  }
}


// fullName: "Carlos GUIDJO"
// lastName: "GUIDJO"
// mail: "a@a.com"
// phone: "96112233"
// profil: "EFBP_PRD_ADM_FUNCTIONAL"
