import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { Observations } from 'src/app/shared/models/observation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-post-observation',
  templateUrl: './post-observation.component.html',
  styleUrls: ['./post-observation.component.css'],
})
export class PostObservationComponent implements OnInit {
  model = new Observations(); choix: string; optionalInput1 = false;
  progress = false;  lineBar = false; validate = 'Envoyer';
  msg = '';
  constructor(
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PostObservationComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.data);
    console.log(this.data.data.data.filename);
  }

    thechoice() {
      console.log(this.choix);
      if (this.choix === 'valider' ) {
        this.optionalInput1  = false;
      } else if (this.choix === 'rejetter') {
        this.optionalInput1  = true;
      }
    }

  save() {
    this.validate = 'Patienter';
    console.log(this.model);
    this.model.filename = this.data.data.data.filename;
    if (!this.choix) {
      Swal.fire({
        type: 'error',
        text: 'Quelle action aimeriez vous exécuter..?',
        title: 'VALIDER ou REJETER ???'
      });
    } else if (this.choix === 'rejetter' && !this.model.errorDescription) {
      this.msg = 'Ce champ est obligatoire';
    } else if (this.choix === 'valider') {
      this.lineBar = true;
      this.api.checkValidation(this.model.filename).subscribe(
        (resp: any) => {
          this.validate = 'OK';
          console.log(resp);
          console.log(resp.status);
          this.lineBar = false;
          this.dialogRef.close();
          Swal.fire({
            type: 'success',
            // text: resp.message,
            text: 'Donnèes validées avec succès',
          });
        },
        (error) => {
          console.log(error);
          this.lineBar = false;
          this.dialogRef.close();
          Swal.fire({
            type: 'error',
            text: error.error.message,
          });
        }
      );
    } else if (this.choix === 'rejetter' && this.model.errorDescription) {
      this.lineBar = true;
      this.api.sendObsevation(this.model).subscribe(
        (resp: any) => {
          this.lineBar = false;
          console.log(resp);
          this.dialogRef.close();
          Swal.fire({
            type: 'success',
            // text: resp.message,
            text: 'Succès de l\'opération',
          });
        },
        (error) => {
          console.log(error);
          this.lineBar = false;
          this.dialogRef.close();
          Swal.fire({
            type: 'error',
            text: error.error.message,
          });
        }
      );
    }
  }
}
