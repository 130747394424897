<h4 style="text-align: center;">FORMULAIRE DE PARAMETRAGE</h4>
<form>

    <div class="form-group" style="width: 100%">
        <label for="example-select">Type Paramètre</label>
        <select class="form-control" id="example-select" [(ngModel)]="model.type" name="type" id="type" disabled>
            <option value="MAIL">MAIL</option>
            <option value="TOPOLOGY_VALIDATION">VALIDATION TOPOLOGIQUE</option>
        </select>
    </div>

    <div class="form-group" style="width: 100%">
        <label for="parameter">Paramètre</label>
        <input type="text" id="parameter" [(ngModel)]="model.key" name="key" class="form-control">
    </div>

    <div class="form-group" style="width: 100%">
        <label for="desc">Description</label>
        <input type="text" id="desc" [(ngModel)]="model.label" name="label" class="form-control">
    </div>


    <div class="form-group" style="width: 100%">
        <label for="mail">Valeur</label>
        <input type="email" pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" [(ngModel)]="model.value" name="value" id="mail" class="form-control" disabled>
    </div>
    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
            Annuler
        </button> &nbsp;
        <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="button" (click)="save()">
            <span>
              <i class="mdi mdi-database-edit"></i>
                Modifier
            </span>
        </button>
    </div>

</form>