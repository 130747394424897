import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { url as apiUrl } from '../../shared/functions/function';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import { Download } from 'src/app/shared/models/upload';
import { PostObservationComponent } from './post-observation/post-observation.component';
import { ApproveFileComponent } from './approve-file/approve-file.component';
import { AddEditUploadFileComponent } from './add-edit-upload-file/add-edit-upload-file.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { PdfRepportComponent } from './pdf-repport/pdf-repport.component';
import { PdfCadatralMapComponent } from './pdf-cadatral-map/pdf-cadatral-map.component';
import { PdfRepertoireComponent } from './pdf-repertoire/pdf-repertoire.component';
import { FileCompareComponent } from './file-compare/file-compare.component';
import { SharedfileComponent } from './sharedfile/sharedfile.component';
import { ApprovalComponent } from '../main-header/approval/approval.component';
import { Router } from '@angular/router';
import {saveAs as importedSaveAs} from "file-saver";


declare let $: any;
declare let require: any;
@Component({
  selector: 'app-field-data-upload',
  templateUrl: './field-data-upload.component.html',
  styleUrls: ['./field-data-upload.component.css'],
})
export class FieldDataUploadComponent implements OnInit, AfterViewInit {
  FileSaver = require('file-saver');
  filename;
  model = new Download();
  lineBar = false;
  lineBar1 = false;
  attente = false;
  press;
  showAlert = false;
  showBody = false;
  @ViewChild('file') file: ElementRef;
  file_name = '';
  j = 0;
  username: string;
  insaeCode: string;
  verify1 = false;
  verify2 = false;
  verify3 = false;
  verify4 = false;
  verify5 = false;
  verify6 = false;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';
  _editDialogRef: MatDialogRef<PostObservationComponent>;
  _editDialogRef1: MatDialogRef<ApproveFileComponent>;
  _editDialogRef2: MatDialogRef<ApprovalComponent>;

  requiredInput2 = false;
  _pagination = true;
  _progress = false;
  show = false;
  fileToUpload: string;
  comm: any;
  arrond: string;
  action: string;
  statut: string;
  code: string;
  agents: string;
  beginAt = new Date();
  endAt = new Date();
  projectCode;
  publicationLocation;
  oldFileName;
  choice;
  choice1;
  choice2;
  choice3;
  message: any;
  dpmt: any;
  comu: any;
  arrd: any;
  fileUpload;
  requiredInput = false;
  optionalInput1 = false;
  optionalInput2 = false;
  datas: any[] = [];

  ACTION: any[] = [];
  STATUT: any[] = [];
  AGENTS: any[] = [];
  showline = true;
  chosenFiles: FileList;
  existingFile: File;
  progresss = 0;
  msg = '';
  i = 1;
  p = 1;
  totalRec: number;

  FileDetail: Observable<any>;

  url = apiUrl('/procedure-d-enregistrement-collectif/v1/jsonFile/') ;
  urlJsonsource = apiUrl('/procedure-d-enregistrement-collectif/v1/jsonSourceFile/') ;
  urlExcSource = apiUrl('/procedure-d-enregistrement-collectif/v1/excelSourceFile/') ;
  
  url2 = apiUrl('/procedure-d-enregistrement-collectif/v1/publicationXlsx/') ;
  url3 = apiUrl('/procedure-d-enregistrement-collectif/v1/downloadPv/') ;
  fileUrl = apiUrl('/procedure-d-enregistrement-collectif/v1/downloadTabModif/') ;

  @Input() progress: number;
  @Input() total: number;
  libe;
  constructor(
    private api: ApiProviders,
    private toast: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private permission: NgxPermissionsService,
    private _editDialogService: EditDialogService<PostObservationComponent>,
    private editDialogService1: EditDialogService<ApproveFileComponent>,
    private editDialogService2: EditDialogService<ApprovalComponent>,
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    console.log(this.ress().roles);
    console.log(this.ress().roles[0]);
    this.libe = this.ress().roles[0];
    // this.move();
    this.press = 'Valider';
    this.testDate();
    // this.username = 'a@a.com';
    this.username = this.User();

    this.allPrestataire();
    this.allOPtionValidation();
    this.allGeoJsonStatut();
    if (this.libe === 'EFBP_PRD_PRESTATAIRE') {
      this.agents = this.User();
      this.action = 'TOUT';
      this.statut = 'TOUT';
      this.code = '';
      this.retrieveAlldatas();
    } else if (this.libe !== 'EFBP_PRD_PRESTATAIRE') {
      this.agents = 'TOUT';
      this.action = 'TOUT';
      this.statut = 'TOUT';
      this.code = '';
      this.retrieveAlldatas();
    }
    this.choice = 'choiceOne';
  }

  lechoix() {
    console.log(this.choice);

    if (this.choice === 'choiceOne') {
      this.optionalInput1 = false;
      // this.optionalInput2  = false;
    } else if (this.choice === 'choiceTwo') {
      this.optionalInput1 = true;
      // this.optionalInput2  = false;
    } else if (this.choice === 'choiceThree') {
      this.optionalInput1 = false;
    }
  }

  chooseFile(event): void {
    this.press = 'Chargement';
    this.lineBar1 = true;

    this.chosenFiles = event.target.files;
    console.log(this.chosenFiles);
    console.log(this.chosenFiles[0]);
    console.log(this.chosenFiles[0].name);
    this.file_name = this.chosenFiles[0].name;
    this.press = 'Continuer';
  }

  move(): void {
    let i = 0;
    if (i === 0) {
      i = 1;
      // tslint:disable-next-line:quotemark
      const elem = document.getElementById('myBar');
      let width = 1;
      const id = setInterval(frame, 10);
      // tslint:disable-next-line:typedef
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
        } else {
          width++;
          elem.style.width = width + '%';
          elem.innerHTML = width + '%';
        }
      }
    }
  }

  rapport_after_approval(code) {
    
    this.api.validationDFileDoc(code).subscribe((resultBlob: Blob) => {
      const downloadURL = URL.createObjectURL(resultBlob);
      window.open(downloadURL);
    });
  }

  testDate() {
    console.log(this.convert(this.beginAt), this.convert(this.endAt));
  }

  async retrieveAlldatas() {
    this._progress = true;
    console.log(this.username);
    (this.libe === 'EFBP_PRD_PRESTATAIRE'
      ? await this.api.allFileUploadrequestByUsername(
          this.username,
          this.action,
          this.statut,
          this.code
        )
      : await this.api.allFileUploadrequestByUsername(
          'TOUT',
          this.action,
          this.statut,
          this.code
        )
    ).subscribe(
      (res: any) => {
        console.log(res);
        console.log(res.body);
        if (res.body.length > 0 && (this.action && this.statut) && res.status === 200) {
          this.p = 1;
          this.datas = res.body;
          this.totalRec = res.body.length;
          this.showAlert = false;
          this._pagination = true;
          this.showBody = true;
          this._progress = false;
        } else if (res.body.length === 0 && (this.action && this.statut) && res.status === 200) {
          this.showAlert = true;
          this.showBody = false;
          this._pagination = false;
          this.showBody = true;
          this._progress = false;
          this.toast.info('Pas de données enregistrées')
          console.log(res.body.length);
        }
        // if(this.totalRec !== 0) {
        //   this.showline = true;
        // } else if(this.totalRec === 0) {
        //   this.showline = false;
        // }
      },
      (error) => {
        console.log(error);
        this.lineBar1 = false;
        this._progress = false;
      }
    );
  }

  async allOPtionValidation() {
    this._progress = true;
    console.log(this.username);
    await this.api.allOPtionValidation().subscribe(
      (res: any) => {
        this._progress = false;
        console.log(res);
        this.ACTION = res.body.object;
        console.log(this.ACTION);
        // this.totalRec = res.body.object.length;
        console.log(this.totalRec);
      },
      (error) => {
        console.log(error);
        this._progress = false;
      }
    );
  }

  async allPrestataire() {
    this._progress = true;
    console.log(this.username);
    await this.api.getPrestataireList().subscribe(
      (res: any) => {
        this._progress = false;
        console.log(res);
        console.log(res.object);
        this.AGENTS = res.object;
        console.log(this.AGENTS);
      },
      (error) => {
        console.log(error);
        this._progress = false;
      }
    );
  }

  async excelfiledown(file) {
    this._progress = true;
    console.log(this.username);
    await this.api.downloadExcelfile(file).subscribe(
      (res: any) => {
        console.log(res);
      },
      (error) => {
        console.log(error);
        this._progress = false;
      }
    );
  }

  async allGeoJsonStatut() {
    this._progress = true;
    console.log(this.username);
    await this.api.allGeoJsonStatut().subscribe(
      (res: any) => {
        this._progress = false;
        console.log(res);
        this.STATUT = res.body.object;
        console.log(this.STATUT);
        // this.totalRec = res.body.object.length;
        console.log(this.totalRec);
      },
      (error) => {
        console.log(error);
        this._progress = false;
      }
    );
  }

  User() {
    const User = window.localStorage.getItem('username');
    return User;
  }

  close(): void {
    $('#uploadFieldData').modal('hide');
    this.lineBar1 = false;
  }



  iframeRepport(file) {
    const editOrAddDialog = this.dialog.open(PdfRepportComponent, {
      disableClose: true,
      data: {
        filename: file,
      },
      width: '95%',
    });
    editOrAddDialog.afterClosed().subscribe((result) => {
      // this._bottomSheetRef.dismiss();
    });
  }

  rapport(filename) {
    this.api.checkGeojsonFileRepport(filename).subscribe((resultBlob: Blob) => {
      // const downloadURL = URL.createObjectURL(resultBlob);
      // window.open(downloadURL);
      importedSaveAs(resultBlob, filename + ".pdf");
    });
  }

  rapport_comparaison(filename) {
    this.api.checkGeojsonFileRepportComp(filename).subscribe((resultBlob: Blob) => {
      // const downloadURL = URL.createObjectURL(resultBlob);
      // window.open(downloadURL);
      importedSaveAs(resultBlob, filename + ".pdf");
    });
  }

  cadastralMap(filename) {
    this.api.checkGeojsonFileMap(filename).subscribe((resultBlob: Blob) => {
      // const downloadURL = URL.createObjectURL(resultBlob);
      // window.open(downloadURL);
      importedSaveAs(resultBlob, filename + ".pdf");
    });
  }

  pvDocument(code) {
    this.api.getpvDocumentNeo(code).subscribe((resultBlob: Blob) => {
      // const downloadURL = URL.createObjectURL(resultBlob);
      // window.open(downloadURL);
      importedSaveAs(resultBlob, code + ".pdf");
    });
  }


  repertoire(data) {
    const editOrAddDialog = this.dialog.open(PdfRepertoireComponent, {
      disableClose: true,
      data: data,
      width: '400px',
    });
    editOrAddDialog.afterClosed().subscribe((result) => {
      this.retrieveAlldatas()
    });
  }


  iframeCadastral(file) {
    const editOrAddDialog = this.dialog.open(PdfCadatralMapComponent, {
      disableClose: true,
      data: {
        filename: file,
      },
      width: '95%',
    });
    editOrAddDialog.afterClosed().subscribe((result) => {
      // this._bottomSheetRef.dismiss();
    });
  }

  iframeRepertoire(file) {
    const editOrAddDialog = this.dialog.open(PdfRepertoireComponent, {
      disableClose: true,
      data: {
        filename: file,
      },
      width: '95%',
    });
    editOrAddDialog.afterClosed().subscribe((result) => {
      // this._bottomSheetRef.dismiss();
    });
  }


  convert(z) {
    const now = new Date(z);
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();

    const finalconv =
      '' + y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
    return finalconv;
  }

  open(data): void {
    this._editDialogRef = this._editDialogService.open(
      PostObservationComponent,
      { data }
    );
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }

  checkUpdated(data): void {
    this._editDialogRef1 = this.editDialogService1.open(ApproveFileComponent, {
      disableClose: true,
      data,
    });
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }

  uploaded(data): void {
    const dialogRef = this.dialog.open(AddEditUploadFileComponent, {
      disableClose: true,
      width: '650px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }
  approvee(data): void {
    this._editDialogRef2 = this.editDialogService2.open( ApprovalComponent, { data });
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe((result) => {
      // this.retrieveAlldatas();
    });
  }
  approve(a): void {
    this.router.navigate(['procedure-d-enregistrement-collectif/approve', a ]);
  }

  shared(data): void {
    const dialogRef = this.dialog.open(SharedfileComponent, {
      disableClose: true,
      width: '650px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }

  compare(data): void {
    const dialogRef = this.dialog.open(FileCompareComponent, {
      disableClose: true,
      width: '650px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }

  updated(data): void {
    const dialogRef = this.dialog.open(AddEditUploadFileComponent, {
      disableClose: true,
      width: '650px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.retrieveAlldatas();
    });
  }

  Token() {
    const Token = window.localStorage.getItem('token');
    return Token;
  }

  ress() {
    const r = JSON.parse(window.localStorage.getItem('789'));
    return r;
  }

  AnnulerProject(id, code) {
    Swal.fire({
      title: 'Etes vous sûr',
      text: 'de vouloir retirer le projet ' + code + ' ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1DD0D9',
      cancelButtonColor: '#F7A47A',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.api.RemoveUpload(id).subscribe(
           (res: any) => {
             console.log()
             this.retrieveAlldatas()
             this.toast.success("Le projet " + code + "a été retiré avec succès", "Vous pouvez reprendre")
           },
           (error) => {
             console.log(error);
             this._progress = false;
           }
         );
      }
    });
  }
}
