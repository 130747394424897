import { baseURL, baseUrls } from './const';

// tslint:disable-next-line:typedef
export function url(path: string) {
  if(baseUrls) {
    return baseUrls + path;
  } else {
    return baseURL + path;
  }
}
