import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { title } from 'process';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { GlobalApprove } from 'src/app/shared/models/observation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-global-approve',
  templateUrl: './global-approve.component.html',
  styleUrls: ['./global-approve.component.css']
})
export class GlobalApproveComponent implements OnInit {
  model = new GlobalApprove(); choix: string; optionalInput1 = false;
  progress = false;  lineBar = false; validate = 'Envoyer';
  msg = ''; Enable = true;
  constructor(
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GlobalApproveComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.code);

    this.checkdecision()
  }

    thechoice() {
      console.log(this.choix);
      if (this.choix === 'valider' ) {
        this.optionalInput1 = false
        this.model.decision  = 'APPROVE';
        this.model.note = "Je suis d'accord avec ce qui est renvoyé dans le tableau de comparaison"
      } else if (this.choix === 'rejetter') {
        this.model.decision  = 'REJECT';
        this.optionalInput1 = true
      }
    }


    save() {
      this.validate = 'Patienter';
      console.log(this.model);
      this.model.codeProject = this.data.code;
      if (!this.choix) {
        Swal.fire({
          type: 'error',
          text: 'VALIDER ou REJETER ???',
          title: 'Quelle action aimeriez vous exécuter..?',
        });
      } else if (this.choix === 'rejetter' && !this.model.note) {
        this.msg = "Saisissez le message d'erreur d'approbation ";
      }  else {
        this.api.globalValidation(this.model,this.model.codeProject, this.model.decision ).subscribe((res: any) => {
          console.log(res); 
          if(res.message === "Opération effectuée avec succès") {
            this.toast.success("Opération effectuée avec succès")
          }
          this.dialogRef.close()
        },
        (error) => {
          console.log(error);
          // Swal.fire({
          //   type: 'error',
          //   text: error.error.message,
          // });
        })
      }
    }


    checkdecision(){
      this.api.checkDecision(this.data.code).subscribe((res: any) => {
        console.log(res)
        console.log(res.object); 
        if (res.object === "ENABLE") {
          this.Enable = true;
          this.toast.success("Tout est OK!!!")
        } else if (res.object === "DISABLE"){
          this.Enable = false;
          this.toast.info("Certaines modifications contienent d'erreur")
        } 
      })
    }

}
