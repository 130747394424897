<!-- ============================================================== -->
<!-- Start Page Content here -->
<!-- ============================================================== -->

<div class="content-page">
    <div class="content">

        <!-- Start Content-->
        <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/main/dash']">Accueil</a></li>
                                <li class="breadcrumb-item"><a [routerLink]="['/main/parameter']">Paramètres</a></li>
                                <li class="breadcrumb-item active">Paramètres Pré-Définis</li>
                            </ol>
                        </div>
                        <h4 class="page-title"></h4>
                    </div>
                </div>
            </div>
            <!-- end page title -->


            <div class="row">

                <div class="col-lg-12">
                    <div class="card-box">
                        <div class="dropdown float-right">
                            <div class="form-group" style="width: 100%;">
                                <mat-radio-group aria-label="Select an option" name="choice" [(ngModel)]="type" (change)="retrieveAlldatas()">
                                    <mat-radio-button value="MAIL" color="primary" [checked]="true">MAIL</mat-radio-button> &nbsp;&nbsp;&nbsp;
                                    <mat-radio-button value="TOPOLOGY_VALIDATION" color="primary">VALIDATION TOPOLOGIQUE</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>


                        <h4 class="header-title">PARAMETRAGE</h4>
                        <p class="sub-header">
                            Liste des paramètres pré-définis
                        </p>

                        <div class="table-responsive">

                            <table class="table mb-0 table-striped">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Paramètre</th>
                                        <th>Valeur</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="_progress">
                                    <tr>
                                        <td class="loading">
                                            <div class="bar"></div>
                                        </td>
                                        <td class="loading">
                                            <div class="bar"></div>
                                        </td>
                                        <td class="loading">
                                            <div class="bar"></div>
                                        </td>
                                        <td class="loading">
                                            <div class="bar"></div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!_progress">
                                    <tr class="table-active" *ngFor="let data of datas | paginate: { id: 'listing_paginated', itemsPerPage: 10, currentPage: p, totalItems: totalRec }">
                                        <td>{{data.label}}</td>
                                        <td>{{data.key}}</td>
                                        <td>{{data.value}}</td>
                                        <td>
                                            <button class="btn btn-warning waves-effect waves-light" (click)="edit({data: data, option: 'EDIT'})">
                                              <i class="mdi mdi-database-edit"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="text-align: center;">
                                <pagination-controls id="listing_paginated" (pageChange)="p = $event" maxSize="5" directionLinks="true" autoHide="true" responsive="true" previousLabel="Prec" nextLabel="Suiv"> </pagination-controls>
                            </div>
                        </div>
                        <!-- end table-responsive-->

                    </div>
                    <!-- end card-box -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->


        </div>
        <!-- container -->

    </div>
    <!-- content -->

    <app-footer-page></app-footer-page>

</div>

<!-- ============================================================== -->
<!-- End Page content -->
<!-- ============================================================== -->
