import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { verify } from 'crypto';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { User } from 'src/app/shared/models/user';

declare let $: any;
declare let checkPwd: any;
@Component({
  selector: 'app-add-edit-page-user',
  templateUrl: './add-edit-page-user.component.html',
  styleUrls: ['./add-edit-page-user.component.css'],
})
export class AddEditPageUserComponent implements OnInit, AfterViewInit {
  donnee: any[] = [];
  Orga: any[] = [];
  OrgaPro: any[] = [];
  controlName = false;
  controleLname = false;
  controlMail = false;
  controlePass = false;
  controleConfPas = false;
  controleContact = false;
  controlePseudo = false;
  controlGroup = false;
  hideBlockPwd = true;
  progress = false;
  lineBar = false;
  msg = '';
  model = new User();
  hide = true;
  hiden = true;
  formTitle = 'FORMULAIRE DE CREATION';
  btnAction = 'Valider';
  code = false;
  message;
  public barLabel = 'Password strength:';
  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditPageUserComponent>
  ) {}

  ngOnInit(): void {
    this.retrieveAlldatas();
    this.retrieveAllOrganistion()
    console.log(this.data);
    console.log(this.data.data);
    if (this.data.option === 'EDIT') {
      this.model.username = this.data.data.username;
      this.hideBlockPwd = false;
      this.formTitle = 'MODIFIER CET UTILISATEUR';
      this.btnAction = 'Modifier';
      // this.model.profil = this.data.data.profile;
      this.model.firstname = this.data.data.fullName;
      this.model.lastname = this.data.data.lastName;
      this.model.mail = this.data.data.mail;
      this.model.phone = this.data.data.phone;
      this.model.organisation = this.data.data.organisation;
      this.retrieveDataOrganis(this.model.organisation)
      this.model.gidName = "selected"
    } else {
    this.model.gidName = "selected"
    this.model.organisation = "selected"
    }
  }

  ngAfterViewInit(): void {
    checkPwd();
    this.champmask()
  }

  champmask () {
    $('#js-phone').inputmask({
      mask: ['+229 99 99 99 99', '+229 99 99 99 99'],
      jitMasking: 3,
      showMaskOnHover: true,
      autoUnmask: true
    });
   }
  // tslint:disable-next-line:typedef
  async retrieveAlldatas() {
    this.progress = true;
    await this.api.getUsersProfile().subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        this.donnee = res.body;
      },
      (error) => {
        console.log(error);
        this.progress = false;
      }
    );
  }



  async retrieveAllOrganistion() {
    this.progress = true;
    await this.api.allOrganisation().subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        this.Orga = res.body.object;
      },
      (error) => {
        console.log(error);
        this.progress = false;
      }
    );
  }

  async retrieveDataOrganis(org) {
    console.log(org)
    this.progress = true;
    await this.api.dataByOrganisation(org).subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        console.log('test', res);
        this.OrgaPro = res.body.object;
        console.log(this.model.gidName)
      },
      (error) => {
        console.log(error);
        this.progress = false;
      }
    );
  }




  save() {
    this.model.gidNumber = null;
    console.log(this.model)
    console.log(this.model.gidName)
    if (this.data.option === 'EDIT') {
      this.model.fullName = this.model.firstname
      this.model.lastName = this.model.lastname
      this.model.profil = this.model.gidName
      console.log(this.model.fullName, this.model.lastname)
    }
    if ( this.data.option === 'ADD' &&  (!this.model.firstname ||  !this.model.lastname || !this.model.mail ||
        !this.model.password || !this.model.confirmPassword || !this.model.phone)
    ) {
      this.inputControl();
    } else if (this.model.password !== this.model.confirmPassword) {
      this.toast.info('réessayer', 'Les mots de passe ne sont pas identiques!');
    } else {
      this.progress = true;
      (this.data.option === 'EDIT' ? this.api.updateUser(this.model) : this.api.adduseuFromdb(this.model)).subscribe(
        (data: any) => {
          console.log(data);
          if (this.data.option === 'EDIT') {
            this.progress = false;
            this.toast.success('OK', 'Modification effectuée avec succès');
            this.dialogRef.close();
          } else if (this.data.option === 'ADD') {
            this.progress = false;
            this.toast.success('OK', 'Ajout effectué avec succès');
            this.dialogRef.close();
          }
          this.progress = false;
        },
        (error) => {
          this.progress = false;
          console.log(error);
          console.log(error.error);
          console.log(error.error.error , error.error.status, error.error.message);
          let e1 = error.error
          // Password must contain at least 1 uppercase characters.,Password must contain at least 1 digit characters.,Password must contain at least 1 special characters.
          if (error.status === 400 && e1.object?.password === "Password must contain at least 1 uppercase characters.,Password must contain at least 1 digit characters.,Password must contain at least 1 special characters.") {
            this.toast.info( 'Le mot de passe doit contenir une lettre majuscule un carctère spècial et des chiffres.');
          } else if (error.status === 400 && e1.error.object?.confirmPassword === "Password must contain at least 1 uppercase characters.,Password must contain at least 1 digit characters.,Password must contain at least 1 special characters.") {
            this.toast.info( 'Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, un carctère spècial et des chiffres ');
          } else if (error.status !== "400 BAD_REQUEST" && (!e1.object?.password || e1.object?.confirmPassword))  {
            this.toast.info("Le mail existe déjà")
          }


        }
      );
    }
  }

  // tslint:disable-next-line:typedef
  inputControl() {
    if (this.model.gidNumber === 'selected') {
      this.controlGroup = true;
      setTimeout(() =>{this.controlGroup = false;}, 2000);
    }
    else if (!this.model.firstname) {
      this.controlName = true;
      setTimeout(() =>{this.controlName = false;}, 2000);

    } else if (!this.model.lastname) {
      this.controleLname = true;
      setTimeout(() =>{this.controleLname = false;}, 2000);

    } else if (!this.model.mail) {
      this.controlMail = true;
      setTimeout(() =>{this.controlMail = false;}, 2000);

    }
    else if (!this.model.phone) {
      this.controleContact = true;
      setTimeout(() =>{this.controleContact = false;}, 2000);

    }
    else if (!this.model.password) {
      this.controlePass = true;
      setTimeout(() =>{this.controlePass = false;}, 2000);

    }
    else if (!this.model.confirmPassword) {
      this.controleConfPas = true;
      setTimeout(() =>{this.controleConfPas = false;}, 2000);

    } 
  }

  // tslint:disable-next-line:typedef
  passwordChanged() {
    const strength = document.getElementById('strength');
    const strongRegex = new RegExp(
      '^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
      'g'
    );
    const mediumRegex = new RegExp(
      '^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
      'g'
    );
    const enoughRegex = new RegExp('(?=.{8,}).*', 'g');
    // const pwd = document.getElementById('password');
    if (this.model.password.length === 0) {
      this.code = true;
      this.message = 'Type Password';
    } else if (false === enoughRegex.test(this.model.password)) {
      this.code = true;
      this.message = 'More Characters';
    } else if (strongRegex.test(this.model.password)) {
      this.code = true;
      this.message = '<span style="color:green">Strong!</span>';
    } else if (mediumRegex.test(this.model.password)) {
      this.code = true;
      this.message = '<span style="color:orange">Medium!</span>';
    } else {
      this.code = true;
      this.message = '<span style="color:red">Weak!</span>';
    }
  }
}
