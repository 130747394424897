import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { InputValidationComponent } from './input-validation/input-validation.component';

@Component({
  selector: 'app-check-input',
  templateUrl: './check-input.component.html',
  styleUrls: ['./check-input.component.css']
})
export class CheckInputComponent implements OnInit {
progress = false;
code = this.activatedroute.snapshot.paramMap.get('filename');
data: any[] = [];
private map = new Map<string, any>();


  constructor(
    private activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private api: ApiProviders,
  ) { }


  ngOnInit(): void {
    this.dataverification();
  }

  // tslint:disable-next-line:typedef
  async dataverification() {
    this.progress = true;
    await this.api.verificationdata(this.code).subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        console.log(res.body);
        console.log(res.body.object);
        this.data = res.body.object
      },
      (error) => {
        console.log(error);
      }
    );
  }

  open(data): void {
    // tslint:disable-next-line:object-literal-shorthand
    const dialogRef = this.dialog.open(InputValidationComponent, {
      disableClose: true,
      width: '650px',
      // tslint:disable-next-line:object-literal-shorthand
      data: this.code,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataverification();
    });
  }
}
