import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-cadatral-map',
  templateUrl: './pdf-cadatral-map.component.html',
  styleUrls: ['./pdf-cadatral-map.component.css'],
})
export class PdfCadatralMapComponent implements OnInit {
  @ViewChild('iframeCadastre') iframe: ElementRef;
  progress = false;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiProviders
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.download();
  }

  download(): void {
    this.progress = true;
    this.api.checkGeojsonFileMap(this.data.filename).subscribe(
      (res) => {
        // this.dataSource = res;
        console.log(res);
        // this.showProgressBar = false;
        // this.showRelevCompte = true;
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          this.progress = false;
          this.iframe.nativeElement.src = reader.result;
        };
      },
      (error) => {
        const reader = new FileReader();
        reader.readAsText(error.error);
        reader.onloadend = () => {
          this.progress = false;
          console.log(reader.result);
          // Swal.fire({
          //   type: 'error',
          //   title: 'Oops...',
          //   text: reader.result.toString()
          // });
        };
      }
    );
  }

}
