<h2 style="text-align: left;" mat-dialog-title style="font-weight: bold" >Trier le document par </h2>
<br />

<div class="row">
    <div class="col-md-12" style="text-align: center;">
        <div class="form-group">
            <div class="form-group" style="width: 100%">
                <mat-radio-group style="justify-content:space-between;" aria-label="Select an option" name="choice" [(ngModel)]="choice" >
                    <mat-radio-button  value="OUI" color="primary" [checked]="true" style="color: black;">Nom </mat-radio-button>   &nbsp;&nbsp;
                    <mat-radio-button value="NON" color="primary" style="color: black;">Numéro parcellaire </mat-radio-button>   &nbsp;&nbsp;
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider> <br />
<mat-dialog-actions align="end">
    <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close> Annuler </button>
    <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" type="button" (click)="lechoix()"  > Valider </button>
</mat-dialog-actions>
