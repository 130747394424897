import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { DialogComponent } from 'src/app/shared/dialogs/dialog-component';
import { UpdatePath } from 'src/app/shared/models/updatePath';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-paramater',
  templateUrl: './update-paramater.component.html',
  styleUrls: ['./update-paramater.component.css']
})
export class UpdateParamaterComponent extends DialogComponent implements OnInit {

  model = new UpdatePath();
  // tslint:disable-next-line:variable-name
  @ViewChild('file') file: ElementRef; image_url: any;  investigatorID: string; insaeCode: string;
  // tslint:disable-next-line:variable-name
  _progress = false; path: string;
  constructor(
    // tslint:disable-next-line:variable-name
    private _api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateParamaterComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.data);

    if (this.data.data.option === 'EDIT') {
      this.model.id = this.data.data.data.id;
      this.model.type = this.data.data.data.type;
      this.model.label = this.data.data.data.label;
      this.model.key = this.data.data.data.key;
      this.model.value = this.data.data.data.value;
    }
  }

 // tslint:disable-next-line:typedef
 save() {
  console.log(this.model);

  this._progress = true;
  (this.data.data.option === 'EDIT' ? this._api.updateParammeter(this.model) : this._api.configParammeter(this.model)).subscribe(

    (resp: any) => {
      console.log(resp);
      Swal.fire({
        type: 'success',
        text: resp.message
      });
      this.dialogRef.close();
    }, (error) => {
      console.log(error);
      Swal.fire({
        type: 'error',
        text: error.error.message
      });
    }
  );
}

}
