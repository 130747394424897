import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import { EditDialogService } from 'src/app/shared/apiProviders/dialogconfig';
import Swal from 'sweetalert2';
import { url as apiUrl } from '../../../shared/functions/function';
import { DataCompareComponent } from '../../data-compare/data-compare.component';
import { ApprovalDataComponent } from './approval-data/approval-data.component';
import { GlobalApproveComponent } from './global-approve/global-approve.component';
import { ValidateChoiceComponent } from './validate-choice/validate-choice.component';

declare let elasticity: any;
@Component({
  selector: 'app-approve-file',
  templateUrl: './approve-file.component.html',
  styleUrls: ['./approve-file.component.css'],
})
export class ApproveFileComponent implements OnInit, AfterViewInit {
  progress = false;
  data: any[] = [];
  proj: any[] = [];
  msg;
  showSuccess = true;
  showWrong = false;
  code = this.activatedroute.snapshot.paramMap.get('code');
  p = 1;
  q = 1;
  k = 1;
  totalRecM: number;
  showDocM = true;
  totalRecA: number;
  showDocA = true;
  totalRecS: number;
  showDocS = true;
  modified: any[] = [];
  contenuModifiee: any[] = [];
  conteuAjoute: any[] = [];
  conteuSupprime: any[] = [];
  objet;
  added: any[] = [];
  deleted: any[] = [];
  listModifiee: any[] = [];
  listSelectModifiee: any[] = [];
  listAjoutee: any[] = [];
  listSelectaAjoutee: any[] = [];

  listSupprimee: any[] = [];
  listSelectSupprimee: any[] = [];

  iVal; idProj;

  numero; attribut;
  ATTLIST: any[] = [];

  checkedAll = false;
  checkedAllAd = false;
  checkedAllDel = false;

  showBtn = false;
  showCheck = false;

  showMessage = false ; showMessageBody = true
  showMessage2 = false; showMessageBody2 = true
  showMessage3 = false; showMessageBody3 = true
  _editDialogRef: MatDialogRef<DataCompareComponent>;
  _editDialogRef1: MatDialogRef<ApprovalDataComponent>;
  constructor(
    private activatedroute: ActivatedRoute,
    private api: ApiProviders,
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastrService,
    private editDialogService: EditDialogService<DataCompareComponent>,
    private editDialogService1: EditDialogService<ApprovalDataComponent>
  ) {}

  ngOnInit(): void {
    this.attribut = 'TOUT'
    this.attributListe()
    this.dataApproved();
    // this.dataApprovedAdded();
    // this.dataApprovedDeleted();
  }

  ngAfterViewInit(): void {
    // elasticity();
  }
  url3 = apiUrl('/procedure-d-enregistrement-collectif/v1/downloadPv/') ;

  fileUrl =  apiUrl('/procedure-d-enregistrement-collectif/v1/downloadTabModif/' +    this.code)  ;
  fileUrlS =  apiUrl('/procedure-d-enregistrement-collectif/v1/generateGlobalApprovalReport/' +    this.code)  ;

  // http://localhost:9090/procedure-d-enregistrement-collectif/v1/generateGlobalApprovalReport/
  retour() {
    this.router.navigate(['/procedure-d-enregistrement-collectif/upload-data'])
  }

  retrieveAlldatas() {
    if (this.attribut === 'TOUT') {
      this.dataApproved()
    } else if (this.attribut !== 'TOUT') {
      this.SearchParcelByATTr()
    }
  }

  async attributListe() {
    await this.api.allPrjCodeAttrList(this.code).subscribe(
      (res: any) => {
        console.log(res);
        console.log(res.object);
        this.ATTLIST = res.object;
        console.log(this.ATTLIST);
      },
      (error) => {
        console.log(error);
        // this._progress = false;
      }
    );
  }

  async SearchParcelByATTr() {
    await this.api.SearchParcelleByAttribu(this.code, this.attribut).subscribe(
      (res: any) => {
        console.log(res);
        console.log(res.object);
        // Replace
        this.modified = res.object.REPLACE;
        console.log(Object.keys(this.modified).length)
        this.totalRecM = Object.keys(this.modified).length
        // ADD
        this.added = res.object.ADD;
        console.log(Object.keys(this.added).length)
        this.totalRecA = Object.keys(this.added).length
        // DELETE
        this.deleted = res.object.REMOVE;
        console.log(Object.keys(this.deleted).length)
        this.totalRecS = Object.keys(this.deleted).length

      },
      (error) => {
        console.log(error);
        // this._progress = false;
      }
    );
  }

  async SearchParcelByNUMO() {
    console.log(this.numero)
    if (this.numero) {
      await this.api.SearchParcelleByNum(this.code, this.numero).subscribe(
        (res: any) => {
          console.log(res);
            // Replace
            this.modified = res.object.REPLACE;
            console.log(Object.keys(this.modified).length)
            this.totalRecM = Object.keys(this.modified).length
            // ADD
            this.added = res.object.ADD;
            console.log(Object.keys(this.added).length)
            this.totalRecA = Object.keys(this.added).length
            // DELETE
            this.deleted = res.object.REMOVE;
            console.log(Object.keys(this.deleted).length)
            this.totalRecS = Object.keys(this.deleted).length

            if (this.totalRecM === 0 ) {
              this.showMessage = true
              this.showMessageBody = false
            } else if (this.totalRecM !== 0) {
              this.showMessage = false
              this.showMessageBody = true
            }

            if (this.totalRecA === 0 ) {
              this.showMessage2 = true
              this.showMessageBody2 = false
            } else if ( this.totalRecA !== 0) {
              this.showMessage2 = false
              this.showMessageBody2 = true
            }

            if (this.totalRecS === 0 ) {
              this.showMessage3 = true
              this.showMessageBody3 = false
            } else if (this.totalRecS !== 0) {
              this.showMessage3 = false
              this.showMessageBody3 = true
            }
        },
        (error) => {
          console.log(error);
          // this._progress = false;
        }
      );
    } else if (this.numero === "") {
      this.dataApproved()
    }
  }

  async dataApproved() {
    this.progress = true;
    await this.api.checkApproval(this.code).subscribe(
      (res: any) => {
        this.progress = false;
        console.log(res);
        console.log(res.body);
        console.log(res.body.object);
        // REPLACE
        this.modified = res.body.object.REPLACE;
        // add atribut checked

        for (let [key, value] of Object.entries(this.modified) ) {
          // console.log(key, value);
          value.forEach(element => {
            element.checked= false
            // console.log('el', element)
          });
      }

      // console.log('modifier ', this.modified)

        // console.log(Object.keys(this.modified).length)

        this.totalRecM = Object.keys(this.modified).length
        // ADD
        this.added = res.body.object.ADD;
        // console.log(Object.keys(this.added).length)
        this.totalRecA = Object.keys(this.added).length
        // DELETE
        this.deleted = res.body.object.REMOVE;
        // console.log(Object.keys(this.deleted).length)
        this.totalRecS = Object.keys(this.deleted).length

        if (this.totalRecM > 0 ) {
          this.showMessage = false
          this.showMessageBody = true
        }

        if (this.totalRecA > 0 ) {
          this.showMessage2 = false
          this.showMessageBody2 = true
        }

        if (this.totalRecS > 0 ) {
          this.showMessage3 = false
          this.showMessageBody3 = true
        }
      },
      (error) => {
        // console.log(error);
        this.progress = false;
        this.showSuccess = false;
        this.showWrong = true;
        this.msg = error.error.message;
      }
    );
  }


  rapport_de_visualisation() {

    this.api.RaportDeVisualisation(this.code).subscribe((resultBlob: Blob) => {
      const downloadURL = URL.createObjectURL(resultBlob);
      window.open(downloadURL);
    });
  }

  open(data): void {
    this._editDialogRef = this.editDialogService.open(DataCompareComponent, {
      data,
    });
    this._editDialogRef.disableClose = true;
    this._editDialogRef.afterClosed().subscribe((result) => {
      // this.retrieveAlldatas();
    });
  }

  CheckAll() {
    const editOrAddDialog = this.dialog.open(GlobalApproveComponent, {
      disableClose: true,
      data: {
        code: this.code,
      },
      // width: '95%',
    });
    editOrAddDialog.afterClosed().subscribe((result) => {
      // this._bottomSheetRef.dismiss();
      this.router.navigate(['/procedure-d-enregistrement-collectif/enregistrement']);
    });
  }

  all() {
    const som = this.totalRecM + this.totalRecA + this.totalRecS;
    console.log(som);
    if (som !== 0) {
      Swal.fire({
        type: 'error',
        text: 'Le téléchargement du fichier de validation est impossible',
        title: 'Donnée(s) en attente de validation',
      });
    } else if (som === 0) {
      this.CheckAll()
    }
  }

  selectSingle(i) {
    this.idProj = this.proj.indexOf(i)
    if(this.idProj > -1) {
      console.log(i)
      this.proj.splice(this.idProj, 1)

      if (this.proj.length === 0) {
        this.showBtn = false
      }
      // this.showBtn = false
    } else {
      this.proj.push(i)
      console.log(i)
      console.log(this.proj)
      if (this.proj.length > 0) {
        this.showBtn = true
      }
    }

    this.updateSelectedModifier();
    this.updateSelectedAjoutee();
    this.updateSelectedSupprimee()

    if (this.listSelectModifiee.length > 0 && this.listSelectaAjoutee.length === 0 && this.listSelectSupprimee. length === 0) {
      this.proj = this.listSelectModifiee
      this.showBtn = true
    }

    if (this.listSelectaAjoutee.length > 0 && this.listSelectModifiee.length === 0 && this.listSelectSupprimee. length === 0) {
      this.proj = this.listSelectaAjoutee
      this.showBtn = true
    }

    if (this.listSelectSupprimee.length > 0 && this.listSelectModifiee.length === 0 && this.listSelectaAjoutee. length === 0) {
      this.proj = this.listSelectSupprimee
      this.showBtn = true
    }

    // console.log( 'Mod ' + this.listSelectModifiee)
    // console.log( 'Ajout ' + this.listSelectaAjoutee)
    // console.log( 'Supp ' + this.listSelectSupprimee)
  }

  selectAllModif() {
    console.log('check all modif', this.checkedAll)
    // console.log('modifier ', this.modified)

    for (let [key, value] of Object.entries(this.modified)) {
      // console.log(key, value);
      value.forEach(element => {
        element.checked = this.checkedAll
        // console.log('el', element)
      });
    }
    this.updateSelectedModifier();
  }


  updateSelectedModifier() {
    this.listSelectModifiee = [];
    for (let [key, value] of Object.entries(this.modified)) {
      // console.log(key, value);
      let values = [];
      value.forEach(element => {
        // element.checked= this.checkedAll
        if (element.checked) {
          values.push(element)
        }
        console.log(values)
      });

      console.log(value)
      console.log(value[0])
      if(values.length !== 0){
        for(let i = 0; i < value.length; i++ ) {
          this.listSelectModifiee.push(values[i].idValidationData);
          console.log(this.listSelectModifiee)
        }
      }

      if(Object.keys(this.modified).length == Object.keys(this.listSelectModifiee).length ){
        this.checkedAll = true
      }else{
        this.checkedAll = false

      }
    }

    console.log('listSelectModifiee', this.listSelectModifiee)
    this.proj = this.listSelectModifiee
    if (this.proj.length > 0) {
      this.showBtn = true
    } else if ( this.checkedAll === false ) {
      this.showBtn = false
    }

    console.log(this.proj)

  }

  selectAllAjouter() {
    console.log('check all add ', this.checkedAllAd)
    // console.log('ajoutee ', this.added)

    for (let [key, value] of Object.entries(this.added)) {
      // console.log(key, value);
      value.forEach(element => {
        element.checked = this.checkedAllAd
        console.log('el', element)
      });
    }
    this.updateSelectedAjoutee();
  }


  updateSelectedAjoutee() {
    this.listSelectaAjoutee = [];
    for (let [key, value] of Object.entries(this.added)) {
      // console.log(key, value);
      let values = [];
      value.forEach(element => {
        // element.checked= this.checkedAll
        if (element.checked) {
          values.push(element)
        }
      });

      console.log(value)
      console.log(value[0])
      if(values.length !== 0){
        for(let i = 0; i < value.length; i++ ) {
          this.listSelectaAjoutee.push(values[i].idValidationData);
          console.log(this.listSelectaAjoutee)
        }
      }

      if(Object.keys(this.added).length == Object.keys(this.listSelectaAjoutee).length ){
        this.checkedAllAd = true
      }else{
        this.checkedAllAd = false

      }
    }

    console.log('listSelectAjoutee', this.listSelectaAjoutee)
    this.proj = this.listSelectaAjoutee
    if (this.proj.length > 0) {
      this.showBtn = true
    } else if ( this.checkedAllAd === false ) {
      this.showBtn = false
    }


  }


  selectAllSupprimee() {
    console.log('check all del', this.checkedAllDel)
    // console.log('supprimee ', this.deleted)

    for (let [key, value] of Object.entries(this.deleted)) {
      console.log(key, value);
      value.forEach(element => {
        element.checked = this.checkedAllDel
        console.log('el', element)
      });
    }
    this.updateSelectedSupprimee();
  }


  updateSelectedSupprimee() {
    this.listSelectSupprimee = [];
    for (let [key, value] of Object.entries(this.deleted)) {
      // console.log(key, value);
      let values = [];
      value.forEach(element => {
        // element.checked= this.checkedAll
        if (element.checked) {
          values.push(element)
        }
      });

      console.log(value)
      console.log(value[0])
      if(values.length !== 0){
        for(let i = 0; i < value.length; i++ ) {
          this.listSelectSupprimee.push(values[i].idValidationData);
          console.log(this.listSelectSupprimee)
        }
      }

      if(Object.keys(this.deleted).length ==Object.keys(this.listSelectSupprimee).length ){
        this.checkedAllDel = true
      }else{
        this.checkedAllDel = false

      }

    }
    console.log('listSelectSupprimee', this.listSelectSupprimee)
    this.proj = this.listSelectSupprimee
    if (this.proj.length > 0) {
      this.showBtn = true
    } else if ( this.proj.length === 0 ) {
      this.showBtn = false
    }

  }

  ApproveSelectChoice() {
    console.log(this.proj)
    if( this.proj.length === 0) {
      this.toast.info('Aucune parcelle sélectionnée')
    } else {
      Swal.fire({
        title: 'Etes vous sûr ?',
        text: 'de vouloir approuver cette/ces parcelle(s)..!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1DD0D9',
        cancelButtonColor: '#F7A47A',
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      }).then((result) => {
        if (result.value) {
          this.api.multipleValidation(this.proj).subscribe(
            (res: any) => {
              console.log(res)
              this.toast.success(res.message)
              this.dataApproved()
            }
          );
        }
      });
    }
  }

  check(): void {
    const dialogRef = this.dialog.open(ValidateChoiceComponent, {
      disableClose: true,
      width: '650px',
      data: this.proj,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataApproved();
      // if (this.modified.length === 0 && this.added.length === 0 && this.deleted.length === 0 ) {
      //   this.showBtn = false
      // }
      if (this.checkedAllAd === true ) {
        this.checkedAllAd = false
        this.showBtn = false
      } else
      if (this.checkedAllDel === true ) {
        this.checkedAllDel = false
        this.showBtn = false
      } else 
      if (this.checkedAll === true ) {
        this.checkedAll = false
        this.showBtn = false
      }
    });
  }

  reject(data): void {
    this._editDialogRef1 = this.editDialogService1.open(ApprovalDataComponent, {
      data,
    });
    this._editDialogRef1.disableClose = true;
    this._editDialogRef1.afterClosed().subscribe((result) => {
      this.dataApproved();
      if (this.checkedAll === true ) {
        this.checkedAll = false
      }
      if (this.checkedAllAd === true ) {
        this.checkedAllAd = false
      }
      if (this.checkedAllDel === true ) {
        this.checkedAllDel = false
      }
    });
  }

  // tslint:disable-next-line:typedef
  file() {
    const som = this.totalRecM + this.totalRecA + this.totalRecS;
    console.log(som);
    if (som !== 0) {
      Swal.fire({
        type: 'error',
        text: 'Le téléchargement du fichier de validation est impossible',
        title: 'Donnée(s) en attente de validation',
      });
    } else if (som === 0) {
      this.api.validationDFileDoc(this.code).subscribe((resultBlob: Blob) => {
        const downloadURL = URL.createObjectURL(resultBlob);
        window.open(downloadURL);
      });
    }
  }

  // tslint:disable-next-line:typedef
  approval(id) {
    console.log(id);
    Swal.fire({
      title: 'Etes vous sûr ?',
      text: 'de vouloir effectuer cette validation..!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1DD0D9',
      cancelButtonColor: '#F7A47A',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.api.approvalDatas(id).subscribe(
          (res: any) => {
            console.log(res);
            if (res.message === 'Opération effectuée avec succès') {
              Swal.fire({
                type: 'success',
                text: 'Suppression reussie',
              });
            }
            this.dataApproved();
          },
          (error) => {
            console.log(error);
            this.progress = true;
          }
        );
      }
    });
  }

  // tslint:disable-next-line:typedef
  pvDocument() {
    this.router.navigate[this.url3, this.code]
    // this.api.getpvDocumentNeo(this.code).subscribe((resultBlob: Blob) => {
    //   const downloadURL = URL.createObjectURL(resultBlob);
    //   window.open(downloadURL);
    // });
  }

}
