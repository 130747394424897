<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                - <span style="font-weight: bold;">{{dayDate | date: 'short' }}</span>  &nbsp; &copy; E-FONCIER BENIN ANDF <a href="">ENREGISTREMENT DE DONNEES CADASTRALES</a>
            </div>
            <!-- <div class="col-md-6">
                <div class="text-md-right footer-links d-none d-sm-block">
                    <a href="javascript:void(0);">A propos de</a>
                    <a href="javascript:void(0);">Aide</a>
                    <a href="javascript:void(0);">Nous contacter</a>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<!-- end Footer -->