import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sharedfile',
  templateUrl: './sharedfile.component.html',
  styleUrls: ['./sharedfile.component.css']
})
export class SharedfileComponent implements OnInit {
  lineBar = false;

  validate = 'Exporter';
  constructor(
    // tslint:disable-next-line:variable-name
    private api: ApiProviders,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SharedfileComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.data.data);
    console.log(this.data.data.filename);
  }


  save() {
    this.validate = 'Patienter';
    this.lineBar = true;
    this.api.sharedFile(this.data.data.filename).subscribe(
      (resp: any) => {
        this.validate = 'OK';
        console.log(resp);
        console.log(resp.status);
        this.lineBar = false;
        this.dialogRef.close();
        Swal.fire({
          type: 'success',
          text: resp.body.object,
          titleText: resp.body.message
        });
      },
      (error) => {
        console.log(error);
        this.lineBar = false;
        this.dialogRef.close();
        Swal.fire({
          type: 'error',
          text: error.error.message,
        });
      }
    );
  }

}
