<h4 class="header-title"> {{formTitle}} </h4>
<p class="sub-header text-danger">
    Tous les champs sont obligatoires*
</p>

<div class="row">
    <div class="col-lg-12">
        <form #userAddForm="ngForm" name="uploadForm" (ngSubmit)="save()">

            <div mat-dialog-content class="buyer">

                <div >
                    <div class="form-group mb-3">
                        <label for="subgroup" class="text-dark">Organisation &nbsp;<span class="text-danger">*</span> </label>
                        <select class="form-control" [(ngModel)]="model.organisation" name="organisation" id="organisation" style="font-size: 15px;" (change)="retrieveDataOrganis(model.organisation)" >
                           <option value="selected" disabled>Selectionner</option>
                           <option *ngFor="let data of Orga" value="{{ data.name }}">{{ data.name }}</option>
                        </select>
                        <span *ngIf="controlGroup" class="text-danger">Attribuer un role à l'utilisateur</span>
                    </div>
                </div>

                <div >
                    <div class="form-group mb-3">
                        <label for="subgroup" class="text-dark">Roles &nbsp;<span class="text-danger">*</span> </label>
                        <select class="form-control" [(ngModel)]="model.gidName" name="gidName" id="gidName" style="font-size: 15px;">
                           <option value="selected" disabled>Selectionner</option>
                           <option *ngFor="let data of OrgaPro" value="{{ data.value}}">{{ data.description }}</option>
                        </select>
                        <span *ngIf="controlGroup" class="text-danger">Attribuer un role à l'utilisateur</span>
                    </div>
                </div>

                <div class="form-group mb-3" >
                    <label for="firstname" class="text-dark">Nom utilisateur&nbsp;<span class="text-danger">*</span> </label>
                    <input type="text" id="simpleinput" name="firstname" [(ngModel)]="model.firstname" class="form-control" placeholder="Saisir le nom de l'utilisateur">
                    <span *ngIf="controlName" class="text-danger">Ce champ est obligatoire</span>
                </div>

                <div class="form-group mb-3">
                    <label for="lastname" class="text-dark">Prénom utilisateur&nbsp;<span class="text-danger">*</span> </label>
                    <input type="text" id="simpleinput" name="lastname" [(ngModel)]="model.lastname" class="form-control" placeholder="Saisir le prénom de l'utilisateur">
                    <span *ngIf="controleLname" class="text-danger">Ce champ est obligatoire</span>
                </div>

                <div class="form-group mb-3" *ngIf="hideBlockPwd" >
                    <label for="mail" class="text-dark">Adresse électronique&nbsp;<span class="text-danger">*</span> </label>
                    <input type="email" id="example-email" name="mail" [(ngModel)]="model.mail" class="form-control" placeholder="Entrer son adresse électeonique">
                    <span *ngIf="controlMail" class="text-danger">Ce champ est obligatoire</span>
                </div>

                <div class="form-group mb-3">
                    <label for="phone" class="text-dark">Numéro de téléphone&nbsp;<span class="text-danger">*</span> </label>
                    <input type="text"  id="example-phone" name="phone" [(ngModel)]="model.phone" class="form-control" placeholder="Entrer le contact de l'utilisateur">
                    <span *ngIf="controleContact" class="text-danger">Ce champ est obligatoire</span>
                </div>

                <div *ngIf="hideBlockPwd">
                    <div class="form-group mb-3">
                        <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
                        <label for="password" class="text-dark">Mot de passe &nbsp;<span class="text-danger">*</span> </label>
                        <div class="input-group input-group-merge">
                            <input [type]="hide ? 'password' : 'text'" id="pwd1" name="password" [(ngModel)]="model.password" class="form-control" placeholder="Mot de passe">
                            <div class="input-group-append" data-password="false">
                                <div class="input-group-text" (click)="hide = !hide">
                                    <i attr.class="{{hide ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                                </div>
                            </div>
                        </div>
                        <app-password-strength-bar [passwordToCheck]="model.password"> </app-password-strength-bar>
                        <span *ngIf="controlePass" class="text-danger">Ce champ est obligatoire</span>
                    </div>
                    <div class="form-group mb-3">
                        <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
                        <label for="confirmPassword" class="text-dark">Confirmer mot de passe &nbsp;<span class="text-danger">*</span> </label>
                        <div class="input-group input-group-merge">
                            <input [type]="hiden ? 'password' : 'text'" id="pwd2" minlength="5" maxlength="50" name="confirmPassword" [(ngModel)]="model.confirmPassword" class="form-control" placeholder="confirmer votre mot de passe">
                            <div class="input-group-append" data-password="false">
                                <div class="input-group-text" (click)="hiden = !hiden">
                                    <i attr.class="{{hiden ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                                </div>
                            </div>
                        </div>
                        <app-password-strength-bar [passwordToCheck]="model.confirmPassword"> </app-password-strength-bar>
                        <span *ngIf="controleConfPas" class="text-danger">Ce champ est obligatoire</span>
                    </div>
                </div>
            </div>

            <mat-divider></mat-divider> <br />

            <div style="float: right;">
                <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close> Annuler
        </button> &nbsp;
                <button style="color: black;" class="btn btn-success btn-rounded waves-effect waves-light" type="submit">
          <span>
            <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
            <!-- <i class="mdi mdi-database-plus"></i> -->
            {{btnAction}}
          </span>
        </button>
            </div>
        </form>
    </div>
    <!-- end col -->
</div>
<!-- end row-->
