<form #pwdForm="ngForm" name="pwdForm" (ngSubmit)="updateMyPwd()">
    <h1 style="text-align: center;">Réinitialiser son mot de passe</h1>

    <div class="form-group mb-3">
        <label for="oldPassword" class="text-dark">Mot de passe actuel &nbsp;<span class="text-danger">*</span>
        </label>
        <div class="input-group input-group-merge">
            <input [type]="hide ? 'password' : 'text'" id="oldPassword" name="oldPassword"
                [(ngModel)]="model.oldPassword" class="form-control" placeholder="ancien mot de passe ">
            <div class="input-group-append" data-password="false">
                <div class="input-group-text" (click)="hide = !hide">
                    <i attr.class="{{hide ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mb-3">
        <label for="newPassword" class="text-dark">Nouveau mot de passe &nbsp;<span class="text-danger">*</span>
        </label>
        <div class="input-group input-group-merge">
            <input [type]="hiden ? 'password' : 'text'" id="newPassword" name="newPassword"
                [(ngModel)]="model.newPassword" class="form-control" placeholder="Nouveau mot de passe">
            <div class="input-group-append" data-password="false">
                <div class="input-group-text" (click)="hiden = !hiden">
                    <i attr.class="{{hiden ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                </div>
            </div>
        </div>
        <app-password-strength-bar [passwordToCheck]="model.newPassword"> </app-password-strength-bar>
    </div>

    <div class="form-group mb-3">
        <label for="confirmPassword" class="text-dark">Confirmer le nouveau mot de passe &nbsp;<span
                class="text-danger">*</span> </label>
        <div class="input-group input-group-merge">
            <input [type]="hidenn ? 'password' : 'text'" id="confirmPassword" name="confirmPassword"
                [(ngModel)]="model.confirmPassword" class="form-control" placeholder="Confirmer nouveau">
            <div class="input-group-append" data-password="false">
                <div class="input-group-text" (click)="hidenn = !hidenn">
                    <i attr.class="{{hidenn ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                </div>
            </div>
        </div>
        <app-password-strength-bar [passwordToCheck]="model.confirmPassword"> </app-password-strength-bar>
    </div>
    <mat-divider></mat-divider> <br />

    <div style="float: right;">
        <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
            Annuler
        </button> &nbsp;
        <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light"  type="submit">
            <span>
                <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1" role="status"
                    aria-hidden="true"></span>
                <!-- <i class="mdi mdi-database-plus"></i> -->
                Valider
            </span>
        </button>
    </div>
</form>