<div class="col-lg-12">
    <form>
        <h3 style="text-align: center;">Réinitialisation Du Mot De Passe</h3> <br>
        <div class="form-group mb-3">
            <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
            <label for="password" class="text-dark">Nom d'utilisateur </label>
            <div class="input-group input-group-merge">
                <input type="text" id="username" name="username" [(ngModel)]="username" class="form-control" disabled>

            </div>
        </div>

        <div class="form-group mb-3">
            <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
            <label for="password" class="text-dark">Nouveau mot de passe &nbsp;<span class="text-danger">*</span> </label>
            <div class="input-group input-group-merge">
                <input [type]="hiden ? 'password' : 'text'" id="pwdU" name="pwdU" [(ngModel)]="pwdU" class="form-control" placeholder="Mot de passe">
                <div class="input-group-append" data-password="false">
                    <div class="input-group-text" (click)="hiden = !hiden">
                        <i attr.class="{{hiden ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                    </div>
                </div>
            </div>
            <app-password-strength-bar [passwordToCheck]="pwdU"> </app-password-strength-bar>
        </div>

        <div class="form-group mb-3">
            <!-- <a href="auth-recoverpw-2.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
            <label for="password" class="text-dark">Confirmer le nouveau mot de passe &nbsp;<span class="text-danger">*</span> </label>
            <div class="input-group input-group-merge">
                <input [type]="hidenn ? 'password' : 'text'" id="password" name="password" [(ngModel)]="model.password" class="form-control" placeholder="Mot de passe">
                <div class="input-group-append" data-password="false">
                    <div class="input-group-text" (click)="hidenn = !hidenn">
                        <i attr.class="{{hidenn ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}}"></i>
                    </div>
                </div>
            </div>
            <app-password-strength-bar [passwordToCheck]="model.password"> </app-password-strength-bar>
        </div>
        <mat-divider></mat-divider> <br />

        <div style="float: right;">
            <button style="color: black;" class="btn btn-outline-light btn-rounded waves-effect" mat-dialog-close>
            Annuler
          </button> &nbsp;
            <button style="color: black;" class="btn btn-outline-warning btn-rounded waves-effect waves-light" type="button" (click)="updatePwd()">
            <span>
              <span *ngIf="progress" class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
              <!-- <i class="mdi mdi-database-plus"></i> -->
              Réinitialiser
            </span>
          </button>
        </div>
    </form>
</div>
<!-- end col -->