import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DataProvider } from './data-providers';
import { url as apiUrl } from '../functions/function';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { UpdatePath } from '../models/updatePath';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalApprove, Observations, Validations } from '../models/observation';
import { Approval } from '../models/correction.model';
import { Download } from '../models/upload';

@Injectable({
  providedIn: 'root',
})
export class ApiProviders {
  PORT: string = this.getport();
  Token() {
    const Token = window.localStorage.getItem('token');
    return Token;
  }

  getport() {
    const port = localStorage.getItem('PORT');
    return port;
  }
  constructor(
    private _http: HttpClient,
    private _data: DataProvider
  ) {}


  private _requestOptions(queryParams?: any): any {
    const o: any = {
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'response',
      responseType: 'json',
      params: queryParams,
    };

    // let token = localStorage.getItem('token');
    const token = this._data.token;

    if (token) {
      o.headers.Authorization = 'Bearer ' + token;
    }

    return o;
  }


  login(user: User) {
    return this._http
      .post(
        apiUrl('/procedure-d-enregistrement-collectif/v1/users/authenticate'),
        {
          username: user.username,
          password: user.password,
        },
        this._requestOptions()
      )
      .pipe(
        map((r: any) => {
          console.log(r);
          console.log(r.body);
          console.log(r.body);
          console.log(r.body.object.token);
          const b = r.body.object;

          if (b.token) {
            this._data.token = b.token;
            return b;
          }
          throw b;
        })
      );
  }

  allFileUploadrequestByUsername(user, action, statut, code) {
    console.log(this.Token());
    return this._http
      .get<any>(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/uploads/' +
            user +
            '?action=' +
            action +
            '&status=' +
            statut + 
            '&projectCode=' +
            code
        ),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  acitiveUser(uid) {
    return this._http
      .put(apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + uid + '/reactive'), null, {
        headers: { Authorization: 'Bearer ' + this.Token() },
      })
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  disabledUser(uid) {
    return this._http
      .put(apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + uid + '/suspend'), null, {
        headers: { Authorization: 'Bearer ' + this.Token() },
      })
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  changePwd(user: User) {
    return this._http
      .put(
        apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + user.username + '/change-password'),
        user,
        {
          headers: { Authorization: 'Bearer ' + this.Token() },
        }
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

    // tslint:disable-next-line:typedef
    currentUserpwd(model) {
      return this._http
        .put(
          apiUrl('/procedure-d-enregistrement-collectif/v1/users/me/changepassword'),  model,
          {
            headers: { Authorization: 'Bearer ' + this.Token() },
          }
        )
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }

  affect(user, idProfile) {
    return this._http
      .put(
        apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + user +'/toprofil/'+ idProfile ), null,
        {
          headers: { Authorization: 'Bearer ' + this.Token() },
        }
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  allOrganisation() {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/users/AllOrganisation'), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }


  dataByOrganisation(d) {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/users/profilsByOrganization/' + d ), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  dashboard() {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/statisticsValues'), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  verificationdata(code) {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/checkNullInput/' + code), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  allOPtionValidation() {
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/parameter/AllOPtionValidation'),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  allGeoJsonStatut() {
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/parameter/AllGeoJsonStatut'),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  listParamAdminConfig(type) {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/parameter/' + type), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  departmentRequest() {
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/spatialunitgroup/departements'),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  communeRequestByDep(id) {
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/spatialunitgroup/communes/' + id),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  arrondisementRequestByCom(id) {
    return this._http
      .get<any>(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/spatialunitgroup/arrondissements/' + id
        ),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  villageRequestByArr(id) {
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/spatialunitgroup/villages/' + id),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  configParammeter(model: UpdatePath) {
    return this._http
      .post(apiUrl('/procedure-d-enregistrement-collectif/v1/parameter'), model, {
        headers: { Authorization: 'Bearer ' + this.Token() },
      })
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  updateParammeter(model: UpdatePath) {
    return this._http
      .put(apiUrl('/procedure-d-enregistrement-collectif/v1/parameter'), model, {
        headers: { Authorization: 'Bearer ' + this.Token() },
      })
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line: typedef
  getPathRequest() {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/uploads/path'), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line: typedef
  sendFileToServer(
    file,
    user,
    beginAt,
    endAt,
    projectCode,
    publicationLocation,
    action,
    oldFileName
  ) {
    const fd: FormData = new FormData();

    fd.append('file', file);
    fd.append('username', user);
    fd.append('beginAt', beginAt);
    fd.append('endAt', endAt);
    fd.append('projectCode', projectCode);
    fd.append('publicationLocation', publicationLocation);
    fd.append('action', action);
    fd.append('oldFileName', oldFileName);
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .post(apiUrl('/procedure-d-enregistrement-collectif/v1/uploads'), fd, httpOption)
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        })
      )
      .toPromise();
  }

  // tslint:disable-next-line:variable-name
  uploadFile( 
    geojson, proces_verbal, repertoire_excel, user, beginAt, endAt,
    projectCode, publicationLocation, action, oldFileName 
   ): Observable<HttpEvent<any>> {
    const fd: FormData = new FormData();

    fd.append('geojson', geojson);
    fd.append('proces_verbal', proces_verbal);
    fd.append('repertoire_excel', repertoire_excel);
    fd.append('username', user);
    fd.append('beginAt', beginAt);
    fd.append('endAt', endAt);
    fd.append('projectCode', projectCode);
    fd.append('publicationLocation', publicationLocation);
    fd.append('action', action);
    fd.append('oldFileName', oldFileName);
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };

    const request = new HttpRequest(
      'POST',
      apiUrl('/procedure-d-enregistrement-collectif/v1/uploads'),
      fd,
      httpOption
    );

    return this._http.request(request);
  }


  // tslint:disable-next-line:variable-name
  uploadV2File( geojson, excel, attachment, proces_verbal, repertoire_excel, user, beginAt, endAt, projectCode, publicationLocation, action, oldFileName ): Observable<HttpEvent<any>> {
    const fd: FormData = new FormData();

    fd.append('geojson', geojson);
    fd.append('excel', excel);
    fd.append('attachment', attachment);
    fd.append('proces_verbal', proces_verbal);
    fd.append('repertoire_excel', repertoire_excel);
    fd.append('username', user);
    fd.append('beginAt', beginAt);
    fd.append('endAt', endAt);
    fd.append('projectCode', projectCode);
    fd.append('publicationLocation', publicationLocation);
    fd.append('action', action);
    fd.append('oldFileName', oldFileName);
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };

    const request = new HttpRequest(
      'POST',
      apiUrl('/procedure-d-enregistrement-collectif/v1/uploads/v2'),
      fd,
      httpOption
    );

    return this._http.request(request);
  }  
  sendObsevation(model: Observations) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .post(
        apiUrl('/procedure-d-enregistrement-collectif/v1/visualValidation/errors'),
        model,
        httpOption
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  checkValidation(filename) {
    console.log(this.Token());

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .post(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/visualValidation/validate/' +
            filename
        ),
        null,
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  globalValidation(model: GlobalApprove, code, decision) {
    console.log(this.Token());

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .post(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/generalApprovalDecision/' +
            code + "/" + decision
        ),
        model,
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  checkDecision(code) {
    console.log(this.Token());

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .get(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/disableApproveAction/'+ code
        ),
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }


  checkInputValidation(filename, model: Validations) {
    console.log(this.Token());

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .put(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/checkNullInput/'+ filename + '/decison'
        ),
        model,
        httpOptions
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // http://localhost:9090/fdportal/efb/v1/registration/transfert/0430406_2021_11_02_11_19_28_631.json"  
  sharedFile(file) {
    return this._http
      .post(
        apiUrl('/procedure-d-enregistrement-collectif/v1/registration/transfert/' + file),
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }


  checkApproval(code) {
    // tslint:disable-next-line:max-line-length
    return this._http
      .get(
        // apiUrl('/procedure-d-enregistrement-collectif/v1/approveInspection/' + code),
        apiUrl('/procedure-d-enregistrement-collectif/v1/approveInspection/v2/' + code),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line: typedef
  sendApprovalCorrection(model: Approval) {
    // tslint:disable-next-line:max-line-length
    return this._http
      .post(
        apiUrl('/procedure-d-enregistrement-collectif/v1/approveInspection'),
        model,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Token(),
          },
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // -----------------------Uservmagment request------------------------

  // tslint:disable-next-line:typedef
  getUserList() {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/users'), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  getUsersProfile() {
    return this._http
      .get<any>(apiUrl('/procedure-d-enregistrement-collectif/v1/users/profiles'), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Token(),
        },
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  adduseuFromdb(model: User) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .post(apiUrl('/procedure-d-enregistrement-collectif/v1/users'), model, httpOption)
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  updateUser(model: User) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .put(apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + model.username), model, httpOption)
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  approvalDatas(id) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .put(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/approveInspection/approve/' + id
        ),
        null,
        httpOption
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  rejectDatas(id, note) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .put(
        apiUrl(
          '/procedure-d-enregistrement-collectif/v1/approveInspection/reject/' +
            id +
            '/' +
            note
        ),
        null,
        httpOption
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  updateDataafterInspection(model: Download) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .put(
        apiUrl('/procedure-d-enregistrement-collectif/v1/updateUploadData/'),
        model,
        httpOption
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  downloadExcelfile(filename) {
    console.log(this.Token());
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/publicationXlsx/' + filename),
        httpOption
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  checkGeojsonFileByUsername(u) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
      }),
    };
    return this._http
      .get<any>(
        apiUrl('/procedure-d-enregistrement-collectif/v1/validation/' + u),
        httpOption
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
   checkGeojsonFileRepport(u) {
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/report/' + u), {
        headers: { 
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + this.Token() 
        },
        responseType: 'blob',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  checkGeojsonFileRepportComp(u) {
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/comparaisosnReport/' + u), {
        headers: { 
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + this.Token() 
        },
        responseType: 'blob',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  getpvDocument(u, v) {
    return this._http
      .get(
        apiUrl('/procedure-d-enregistrement-collectif/v1/AllAttachments/' + u + '/' + v),
        {
          headers: { 
            'Content-Type': 'application/pdf',
            Authorization: 'Bearer ' + this.Token() 
          },
          responseType: 'blob',
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  getpvDocumentNeo(code) {
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/downloadPv/' + code), {
        headers: { 
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + this.Token() 
        },
        responseType: 'blob',
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  checkGeojsonFilePublication(u, order) {
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/publication/' + u + '?order=' + order), {
        headers: { 
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + this.Token() 
        },
        responseType: 'blob',
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  checkGeojsonFileMap(u) {
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/map/' + u), {
        headers: { 
          'Content-Type': 'application/pdf',
          Authorization: 'Bearer ' + this.Token() 
        },
        responseType: 'blob',
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  validationDFileDoc(u) {
    return this._http
      .get(
        apiUrl('/procedure-d-enregistrement-collectif/v1/generateApprovalReport/' + u),
        {
          headers: { 
            'Content-Type': 'application/pdf',
            Authorization: 'Bearer ' + this.Token() 
          },
          responseType: 'blob',
        }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }


  RaportDeVisualisation(c) {
    return this._http
      .get(
        apiUrl('/procedure-d-enregistrement-collectif/v1/generateGlobalApprovalReport/' + c),
        {
          headers: { 
            'Content-Type': 'application/pdf',
            Authorization: 'Bearer ' + this.Token() 
          },
          responseType: 'blob',
        }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  // tslint:disable-next-line:typedef
  getUserInformation(u) {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
        'Content-Type': 'application/json',
      }),
    };
    return this._http.get(apiUrl('/procedure-d-enregistrement-collectif/v1/users/' + u), httpOption).pipe(
      map((resp) => {
        return resp;
      })
    );
  }


  // tslint:disable-next-line:typedef
  getPrestataireList() {
    const httpOption = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.Token(),
        'Content-Type': 'application/json',
      }),
    };
    return this._http
      .get(apiUrl('/procedure-d-enregistrement-collectif/v1/users/byProfil/EFBP_PRD_PRESTATAIRE'), {
        headers: { Authorization: 'Bearer ' + this.Token() },
      })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

    // tslint:disable-next-line:typedef
    RemoveUpload(id) {
      const httpOption = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.Token(),
        }),
      };
      return this._http
        .delete(apiUrl('/procedure-d-enregistrement-collectif/v1/removeDatas/' + id), httpOption)
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }
    

    multipleValidation(data) {
      console.log(data)
      return this._http
        .put(apiUrl('/procedure-d-enregistrement-collectif/v1/multipleApproveInspection/approve'), data, {
          headers: { Authorization: 'Bearer ' + this.Token() },
        })
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }

    multipleRejValidation(data, note) {
      return this._http
        .put(apiUrl('/procedure-d-enregistrement-collectif/v1/multipleApproveInspection/reject/' + data + '/' + note ), null, {
          headers: { Authorization: 'Bearer ' + this.Token() },
        })
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }

    allPrjCodeAttrList(code) {
      return this._http
        .get(apiUrl('/procedure-d-enregistrement-collectif/v1/findAllDisticntAttributs/v2/' + code), {
          headers: { Authorization: 'Bearer ' + this.Token() },
        })
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }

    SearchParcelleByAttribu(code, attr) {
      return this._http
        .get(apiUrl('/procedure-d-enregistrement-collectif/v1/approveInspectionByAttribut/v2/'+ code +'/' + attr), {
          headers: { Authorization: 'Bearer ' + this.Token() },
        })
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }

    SearchParcelleByNum(code, num) {
      return this._http
        .get(apiUrl('/procedure-d-enregistrement-collectif/v1/approveInspectionByParcel/v2/' + code + '/'  + num), {
          headers: { Authorization: 'Bearer ' + this.Token() },
        })
        .pipe(
          map((resp: Response) => {
            return resp;
          })
        );
    }


}
