import { Component, OnInit, AfterViewInit } from '@angular/core';
import { timeStamp } from 'console';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';

declare let general: any;
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit, AfterViewInit {
datas; progress = false;
totalProjet; openProject; closedProject;
totalParcels; parcelConflictuel; parcelConvenu;
totalUpload; uploadCorrect; uploadIncorrect;
totalInspection; inspectionClosed; inspectionOpen;

  constructor(
    private api: ApiProviders,
  ) { }


  ngOnInit(): void {
    this.retrieveAlldatas();
  }


  ngAfterViewInit(): void {
  }

    // tslint:disable-next-line:typedef
    async retrieveAlldatas() {
      this.progress = true;
      await this.api.dashboard().subscribe((res: any) => {
        this.progress = false;
        console.log(res);
        console.log(res.body.object);
        this.datas = res.body.object;
        console.log(this.datas);
        this.totalProjet = this.datas.totalProjets;
        this.openProject = this.datas.totalProjetOpen;
        this.closedProject = this.datas.totalProjetClose;

        this.totalParcels = this.datas.totalParcels;
        this.parcelConflictuel = this.datas.totalParcelsConflituel;
        this.parcelConvenu = this.datas.totalParcelsConvenus;

        this.totalUpload = this.datas.totalUpload;
        this.uploadCorrect = this.datas.totalUploadCorrect;
        this.uploadIncorrect = this.datas.totalUploadIncorrect;

        this.totalInspection = this.datas.totalInspectionPublic;
        this.inspectionClosed = this.datas.totalInspectionPublicClose;
        this.inspectionOpen = this.datas.totalInspectionPublicOpen;

      }, (error) => {
        console.log(error);
        this.progress = false;
      }
      );
    }
}
