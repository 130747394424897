import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiProviders } from 'src/app/shared/apiProviders/api-providers.service';

@Component({
  selector: 'app-validate-choice',
  templateUrl: './validate-choice.component.html',
  styleUrls: ['./validate-choice.component.css']
})
export class ValidateChoiceComponent implements OnInit {

  note: any; choix: string; optionalInput1 = false; Enable = true; showRejet = false; showApp = false;
  loader = false;
  libelle = "Approuver"
  libellee = "Rejetter"
  constructor( 
    private api: ApiProviders,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ValidateChoiceComponent>
  ) {}


  ngOnInit(): void {
    console.log(this.data)
  }

  thechoice() {
    console.log(this.choix);
    if (this.choix === 'valider' ) {
      this.optionalInput1 = false
      this.showApp = true;
      this.showRejet = false;
    } else if (this.choix === 'rejetter') {
      this.optionalInput1 = true
      this.showApp = false;
      this.showRejet = true;
    }
  }

  aproverlaSelect() {
    console.log(this.data)
    this.loader = true
    this.libelle = "En cours de traitement"
    this.api.multipleValidation(this.data).subscribe(
      (res: any) => {
        console.log(res)
        if (res.status === "200 OK") {
          this.loader = false
          this.toast.success(res.message)
        }
        this.dialogRef.close()
      },
      (error) => {
        console.log(error);
        if (error.status !== "200 OK") {
          this.loader = false
          this.toast.error("Echec de l'opération")
        }
        this.dialogRef.close()
      }
    );
  }

  rejetterlaSelect() {
    this.loader = true
    this.libellee = "En cours de traitement"
    this.api.multipleRejValidation(this.data, this.note).subscribe(
      (res: any) => {
        console.log(res)
        if (res.status === "200 OK") {
          this.loader = false
          this.toast.success(res.message)
        }
        this.dialogRef.close()
      },
      (error) => {
        console.log(error);
        if (error.status !== "200 OK") {
          this.loader = false
          this.toast.error("Echec de l'opération")
        }
        this.dialogRef.close()
      }
    );
  }

}
